import { useEffect, useState } from "react";
import { Star } from "../decoration/star/Star";
import { preLoadImages } from "./props/carousel-props";
import "./scss/Carousel.scss";
import { useInterval } from "./utils/utils";

export interface CarouselSlide {
  title: string;
  rotation: number;
  description: string;
  image: any;
}
export function Carousel(props: {
  isInView: boolean;
  slides: CarouselSlide[];
}) {
  // let carousel: HTMLDivElement | null;
  const [activeSlide, setActiveSlide] = useState(0);
  const [animating, setAnimating] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    preLoadImages();
  }, []);
  useEffect(() => {
    const carousel = document.body.querySelector(
      ".ha-website-carousel-image-container"
    );
    if (props.isInView && carousel && activeSlide !== animating) {
      carousel.classList.add("animate");
    }
    setTimeout(() => {
      if (carousel && props.isInView) {
        setActiveSlide(animating);
      }
    }, 200);
  }, [animating, activeSlide]);

  useEffect(() => {
    const carousel = document.body.querySelector(
      ".ha-website-carousel-image-container"
    );
    setTimeout(() => {
      if (carousel && activeSlide === animating) {
        carousel.classList.remove("animate");
        carousel.classList.remove("forward");
        carousel.classList.remove("backward");
      }
    }, 200);
  }, [activeSlide, animating]);

  const clickPagination = (current: number, direction: 1 | -1) => {
    const carousel = document.body.querySelector(
      ".ha-website-carousel-image-container"
    );
    if (activeSlide === animating) {
      const nextItem =
        current + direction > props.slides.length - 1
          ? 0
          : current + direction < 0
          ? props.slides.length - 1
          : current + direction;
      if (carousel && direction === 1) {
        carousel.classList.add("forward");
      }
      if (carousel && direction === -1) {
        carousel.classList.add("backward");
      }
      setAnimating(nextItem);
    }
  };

  const pauseAnimation = () => {
    setIsPaused(true);
  };
  const startAnimation = () => {
    setIsPaused(false);
  };
  useInterval(
    () => {
      clickPagination(activeSlide, 1);
    },
    !isPaused && props.isInView ? 12000 : null
  );

  return (
    <div className="ha-website-carousel-container ha-website-grid-column-start-1 ha-website-grid-column-end-5">
      <Star
        shape={"star"}
        onMouseOver={() => pauseAnimation()}
        onMouseOut={() => startAnimation()}
        className="ha-website-carousel-star"
        loading={
          !isPaused && props.isInView
            ? {
                color: "white",
                duration: 12,
              }
            : undefined
        }
        content={
          <div
            className="ha-website-carousel-content-text"
            style={{
              transform: `rotate(${props.slides[activeSlide].rotation}deg)`,
            }}
          >
            <div className="ha-website-spacer-vertical-medium" />
            <h5 className="web center ha-website-white">
              {props.slides[activeSlide].title}
            </h5>
            <div className="ha-website-spacer-vertical-xsmall" />
            <div className="center ha-website-white ha-website-text-content medium">
              {props.slides[activeSlide].description}
            </div>
            <div className="ha-website-spacer-vertical-xsmall" />
            <div className="ha-website-carousel-scrubber">
              <button
                onClick={() => clickPagination(activeSlide, -1)}
                className="ha-website-button large ghost square"
              >
                <svg
                  style={{ pointerEvents: "none" }}
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="ArrowLeft">
                    <path
                      id="color1 (Stroke)"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.9622 15.4148H11.8478L15.8478 11.4148L13.9622 9.52914L6.74321 16.7481L13.9622 23.967L15.8478 22.0814L11.8478 18.0814H25.9622V15.4148Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </button>
              <div className="ha-website-spacer-horizontal-xsmall" />
              <button
                onClick={() => clickPagination(activeSlide, 1)}
                className="ha-website-button large ghost square"
              >
                <svg
                  style={{ pointerEvents: "none" }}
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.08252 15.5339H21.1969L17.1969 11.5339L19.0825 9.64828L26.3015 16.8672L19.0825 24.0862L17.1969 22.2006L21.1969 18.2006H7.08252L7.08252 15.5339Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        }
        size={28}
        color="#3F20FD"
      />
      <div
        onMouseOver={() => pauseAnimation()}
        onMouseOut={() => startAnimation()}
        className="ha-website-carousel-image-container"
      >
        {/* <div className='ha-website-carousel-transition-1' /> */}
        {/* <div className='ha-website-carousel-transition-2' /> */}
        {/* <div className='ha-website-carousel-transition-3' /> */}
        <img alt="Illustration" src={props.slides[activeSlide].image} />
      </div>
      <div className="ha-website-carousel-filler" />
    </div>
  );
}
