import { Carousel } from "../../carousel/Carousel";
import { carouselProps } from "../../carousel/props/carousel-props";
import { Star } from "../../decoration/star/Star";
import { SocialProof } from "./components/SocialProof";
import { Features } from "./components/Features";
import { homepageFeatureProps } from "./components/props/HomepageFeatures";
import { Components } from "./components/Components";
import { componentProps } from "./components/props/Components";
import React from "./resources/React.svg";
import Vue from "./resources/Vue.svg";
import Figma from "./resources/Figma.svg";
import Angular from "./resources/Angular.svg";
import Retool from "./resources/Retool.svg";
import Webflow from "./resources/Webflow.svg";
import "./scss/Homepage.scss";
import { BlogFeature } from "./components/BlogFeature";
import {
  HaButton,
  HaColorPicker,
  HaDatePicker,
  HaSlider,
  HaSliderThumb,
  HaSliderTrack,
  HaIcon,
  HaInput,
  HaList,
  HaListItem,
  HaPopover,
  HaRadio,
  HaRadioGroup,
  HaSelect,
  HaSpacer,
  HaThemeContext,
  HaToastProvider,
  HaProgress,
  HaBreakpointSensor,
} from "@blocksfabrik/harmony-react";
import {
  HarmonyButtonSize,
  HarmonyColorType,
  HarmonyFillStyle,
  HarmonyIconNames,
  HarmonyInputErrorTypes,
  HarmonySelectionType,
  HarmonySize,
  HarmonySpacerSize,
  HarmonyTheme,
} from "@blocksfabrik/harmony-core";
import { useState } from "react";
import { useInterval } from "../../carousel/utils/utils";

export function Homepage(props: { onRequestAccess: () => void }) {
  const [isDraggingColorPicker, setIsDraggingColorPicker] = useState(false);
  const [isCarouselInView, setIsCarouselInView] = useState(false);
  const defaultBackgroundColor = "#080324";
  const [breakpoint, setBreakpoint] = useState("desktop");
  const [isToastOpen, setToastOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(
    defaultBackgroundColor
  );
  const exampleClasses =
    "ha-website-example-scale ha-white-background ha-size-border-radius-8 ha-size-padding-24";
  const checkAndSetIsInView = () => {
    const carousel = document.body.querySelector(
      ".ha-website-carousel-container"
    );
    if (carousel) {
      const carouselRect = carousel.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const carouselTopLimit = carouselRect.y;
      const carouselBottomLimit = carouselRect.y + carouselRect.height;
      setIsCarouselInView(
        carouselTopLimit < windowHeight &&
          carouselBottomLimit - carouselRect.height * 0.025 < windowHeight
      );
    }
  };
  const addScrollListener = () => {
    const addColor = () => {
      if (backgroundColor !== defaultBackgroundColor) {
        setBackgroundColor(defaultBackgroundColor);
      }
      checkAndSetIsInView();
    };
    document.removeEventListener("scroll", addColor);
    document.addEventListener("scroll", addColor);
  };
  addScrollListener();
  // checkAndSetIsInView();
  useInterval(
    () => {
      setBackgroundColor(defaultBackgroundColor);
    },
    !isDraggingColorPicker ? 3000 : null
  );

  let toastProvider: any;
  return (
    <>
      <div className="ha-website-page-appear ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <h2 className="web ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        Create Your Design System Without
        <br />
        All The Work
        <Star
          key="Hero star homepage"
          className="ha-website-hero-star"
          content={
            <>
              <h6 className="web ha-website-white">Backed by</h6>
              <div className="ha-website-spacer-vertical-xsmall" />
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_71_4203)">
                  <path
                    d="M0.3396 0.294632L32.3396 0.294632V32.2946H0.3396L0.3396 0.294632Z"
                    fill="white"
                  />
                  <path
                    d="M15.2726 18.4277L9.72656 8.08174H12.2866L15.4866 14.5887C15.4866 14.6957 15.5936 14.8017 15.6996 14.9087C15.8056 15.0157 15.8066 15.1217 15.9126 15.3357L16.0196 15.4427V15.5497C16.1266 15.7627 16.1266 15.8697 16.2326 16.0827C16.3396 16.1897 16.3396 16.4027 16.4456 16.5097C16.5526 16.1897 16.7656 15.9767 16.8726 15.5497C16.9796 15.2297 17.1926 14.9097 17.4056 14.5897L20.6056 8.08274H22.9526L17.4066 18.5347V25.1477H15.2736L15.2726 18.4277Z"
                    fill="#F26625"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_71_4203">
                    <rect
                      width="32"
                      height="32"
                      fill="white"
                      transform="translate(0.3396 0.294632)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </>
          }
          size={10}
          color="#F26522"
        />
      </h2>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
        The new standard for creating design systems. Ship design and code from
        a single source of truth.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <HaButton
          size={HarmonySize.Large}
          colorType={HarmonyColorType.Primary}
          onHaClick={() => props.onRequestAccess()}
        >
          <span className="ha-body-text-weight-medium">Get Early Access</span>
        </HaButton>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <Carousel isInView={isCarouselInView} {...carouselProps} />
      <div className="ha-website-grid-filler-carousel ha-website-grid-long-width ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <SocialProof />
        <h1 className="web ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5">
          More Features...
          <Star
            key="More features"
            shape="star"
            className="ha-website-hero-features-star"
            content={<h4 className="web ha-website-white">Many</h4>}
            size={16}
            color="#3F20FD"
          />
        </h1>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
        <h2 className="web ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5">
          For Designers
        </h2>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
        <Features
          features={homepageFeatureProps.features.filter(
            (f) => f.category === "Designers"
          )}
        />
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
        <h2 className="web ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5">
          For Developers
        </h2>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
        <Features
          features={homepageFeatureProps.features.filter(
            (f) => f.category === "Developers"
          )}
        />
        <h2 className="web ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5">
          For Everyone
        </h2>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
        <Features
          features={homepageFeatureProps.features.filter(
            (f) => f.category === "Both"
          )}
        />
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
        <h2 className="web ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-4">
          <span style={{ position: "relative", zIndex: 1 }}>
            A Growing Catalog Of Components
          </span>
          <Star
            shape="plus"
            className="ha-website-hero-components-star"
            content={
              <h4 className="web center ha-website-white">Fully Extensible</h4>
            }
            size={48}
            color="#3F20FD"
          />
        </h2>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
        <div className="ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
          Your design system ships with more than 50 components, with more being
          added all the time.
        </div>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
        <HaBreakpointSensor
          breakpoints={[
            {
              name: "mobile",
              device: "screen",
              maxWidth: 800,
            },
            {
              name: "desktop",
              device: "screen",
              minWidth: 801,
            },
          ]}
          onHaBreakpointMatched={(e) => setBreakpoint(e.detail)}
        />
        {breakpoint === "desktop" && (
          <div className="ha-website-component-demos ha-website-grid-column-start-1 ha-website-grid-column-end-5">
            <HaThemeContext showBackground={false} theme={HarmonyTheme.light}>
              <div className="ha-component-display ha-display-flex ha-display-flex-row ha-display-flex-align-items-flexstart">
                <div className={exampleClasses}>
                  <HaDatePicker value={new Date()}></HaDatePicker>
                </div>
                <HaSpacer orientation="horizontal" size={HarmonySpacerSize.L} />
                <div className={exampleClasses}>
                  <HaColorPicker
                    onMouseDown={() => setIsDraggingColorPicker(true)}
                    onMouseUp={() => setIsDraggingColorPicker(false)}
                    onMouseOut={() => {
                      if (isDraggingColorPicker) {
                        setIsDraggingColorPicker(false);
                      }
                    }}
                    onHaColorChange={(e) => {
                      setBackgroundColor((e.target as any).value);
                    }}
                    value={backgroundColor}
                  ></HaColorPicker>
                </div>
                <HaSpacer orientation="horizontal" size={HarmonySpacerSize.L} />
                <div
                  style={{ flexShrink: 0 }}
                  className={`ha-display-flex ha-display-flex-column ${exampleClasses}`}
                >
                  <HaButton
                    active={isToastOpen}
                    fillStyle={
                      isToastOpen
                        ? HarmonyFillStyle.Ghost
                        : HarmonyFillStyle.Solid
                    }
                    colorType={HarmonyColorType.Primary}
                    size={HarmonySize.Normal as HarmonyButtonSize}
                    onHaClick={() => {
                      if (!isToastOpen) {
                        const title = "Congratulations";
                        const props = {
                          toastTitle: title,
                          toastDescription: "You clicked a button! ",
                          showCloseAffordance: true,
                        };
                        toastProvider.openConstructive({
                          ...props,
                        });
                        setToastOpen(true);
                        setTimeout(() => {
                          setToastOpen(false);
                        }, 3000);
                      }
                    }}
                  >
                    {isToastOpen && (
                      <HaProgress
                        slot="progress"
                        colorType={HarmonyColorType.Primary}
                        shape="circular"
                      ></HaProgress>
                    )}
                    <span>Click me</span>
                  </HaButton>
                  <HaSpacer orientation="vertical" size={HarmonySpacerSize.L} />
                  <HaRadioGroup name="radio-group">
                    <HaRadio id="1" name="radio-group" >
                      <span>Value 1</span>
                    </HaRadio>
                    <HaRadio id="2" name="radio-group">
                      <span>Value 2</span>
                    </HaRadio>
                    <HaRadio id="3" name="radio-group">
                      <span>Value 3</span>
                    </HaRadio>
                  </HaRadioGroup>
                </div>
                <HaSpacer orientation="horizontal" size={HarmonySpacerSize.L} />
                <div className="ha-display-flex ha-display-flex-column">
                  <div className={exampleClasses}>
                    <HaSelect
                      selectionType={HarmonySelectionType.Single}
                      showInputError={HarmonyInputErrorTypes.Inline}
                    >
                      <HaInput slot="select-target">
                        <HaIcon
                          iconName={HarmonyIconNames.Target}
                          slot="icon"
                        />
                        <HaIcon
                          iconName={HarmonyIconNames.CaretDown}
                          slot="right"
                        />
                      </HaInput>
                      <HaPopover overlayClassname="ha-website-example-scale">
                        <HaList>
                          <HaListItem value={400}>Option 1</HaListItem>
                          <HaListItem value={600}>Option 2</HaListItem>
                          <HaListItem value={800}>Option 3</HaListItem>
                          <HaListItem value={1000}>Option 4</HaListItem>
                        </HaList>
                      </HaPopover>
                    </HaSelect>
                  </div>
                  <HaSpacer orientation="vertical" size={HarmonySpacerSize.L} />
                  <div className={exampleClasses}>
                    <HaSlider min={0} max={100} tickInterval={20} step={10}>
                      <HaSliderThumb
                        min={10}
                        thumbId="outer-1"
                        pairedId="outer-2"
                        value={10}
                      />
                      <HaSliderThumb
                        min={20}
                        thumbId="inner-1"
                        pairedId="inner-2"
                        value={30}
                      />
                      <HaSliderThumb
                        max={60}
                        thumbId="inner-2"
                        pairedId="inner-1"
                        value={50}
                      />
                      <HaSliderThumb
                        thumbId="outer-2"
                        pairedId="outer-1"
                        value={90}
                      />
                      <HaSliderTrack
                        color={HarmonyColorType.Constructive}
                        minId="outer-1"
                        maxId="outer-2"
                      />
                      <HaSliderTrack
                        color={HarmonyColorType.Warning}
                        minId="inner-1"
                        maxId="inner-2"
                      />
                    </HaSlider>
                  </div>
                </div>
              </div>
            </HaThemeContext>
          </div>
        )}
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
        <Components {...componentProps} />
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
        <div
          style={{ backgroundColor: `${backgroundColor}` }}
          className="ha-website-carousel-filler"
        />
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
      <h2 className="web ha-website-grid-column-start-1 ha-website-grid-column-end-4">
        Use Your Favorite Tools
      </h2>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
        We integrate with the tools you love so you can focus on doing things
        that matter most.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-socialproof-tools ha-display-flex ha-display-flex-direction-row ha-display-flex-align-items-center ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <img className="ha-website-integration-logo" alt="Figma" src={Figma} />
        <div className="ha-website-spacer-horizontal-small" />
        <img className="ha-website-integration-logo" alt="React" src={React} />
        <div className="ha-website-spacer-horizontal-small" />
        <img className="ha-website-integration-logo" alt="Vue" src={Vue} />
        <div className="ha-website-spacer-horizontal-small" />
        <img
          className="ha-website-integration-logo"
          alt="Angular"
          src={Angular}
        />
        <div className="ha-website-spacer-horizontal-small" />
        <img
          className="ha-website-integration-logo small"
          alt="Webflow"
          src={Webflow}
        />
        <div className="ha-website-spacer-horizontal-small" />
        <img
          className="ha-website-integration-logo small"
          alt="Reetool"
          src={Retool}
        />
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
      <BlogFeature />
      <HaToastProvider
        style={{ zIndex: 100 }}
        ref={(ref) => (toastProvider = ref)}
      />
    </>
  );
}
