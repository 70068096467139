import {
  HarmonyColorName,
  HarmonyColorType,
  HarmonyFillStyle,
  HarmonyIconNames,
  HarmonyInputErrorTypes,
  HarmonySelectionType,
  HarmonySize,
  HarmonySpacerSize,
} from "@blocksfabrik/harmony-core";
import {
  HaAccordion,
  HaAvatar,
  HaBadge,
  HaBanner,
  HaBreakpointSensor,
  HaButton,
  HaDisplayTable,
  HaDisplayTableBody,
  HaDisplayTableCell,
  HaDisplayTableRow,
  HaIcon,
  HaInfoDisplay,
  HaInput,
  HaList,
  HaListItem,
  HaPopover,
  HaScrollArea,
  HaSelect,
  HaSeparator,
  HaSpacer,
  HaTag,
  HaTagInput,
  HaThemeContext,
  HaTree,
  HaTreeItem,
} from "@blocksfabrik/harmony-react";
import { useRef, useState } from "react";
import "./scss/Demo.scss";

export const defaultCards = [
  {
    status: "Open",
    company: "Spokenly",
    location: "USA",
    design: "Figma",
    dev: "React",
    team: 8,
  },
  {
    status: "Open",
    company: "Appyc",
    location: "Poland",
    design: "Figma",
    dev: "React",
    team: 45,
  },
  {
    status: "Open",
    company: "Hiremate",
    location: "USA",
    design: "Figma",
    dev: "React",
    team: 32,
  },
  {
    status: "Contract",
    company: "TechSeed",
    location: "Ukraine",
    design: "Figma",
    dev: "React",
    team: 47,
  },
  {
    status: "Open",
    company: "Spiro",
    location: "Greece",
    design: "Figma",
    dev: "React",
    team: 6,
  },
  {
    status: "Won",
    company: "Osmo",
    location: "Italy",
    design: "Figma",
    dev: "JS",
    team: 15,
  },
  {
    status: "Open",
    company: "Zetek",
    location: "Italy",
    design: "Figma",
    dev: "React",
    team: 8,
  },
  {
    status: "Won",
    company: "Enact",
    location: "USA",
    design: "Figma",
    dev: "Vue",
    team: 11,
  },
  {
    status: "Open",
    company: "Jibon",
    location: "USA",
    design: "Figma",
    dev: "React",
    team: 10,
  },
  {
    status: "Won",
    company: "Motely",
    location: "France",
    design: "Figma",
    dev: "React",
    team: 12,
  },
  {
    status: "Open",
    company: "Hyperer",
    location: "USA",
    design: "Figma",
    dev: "React",
    team: 5,
  },
];
export function Demo() {
  const ref = useRef<any>();
  const [selectValue, setSelectValue] = useState("ACME Inc");
  const [filters, setFilters] = useState(["Open"]);
  const [metricsOpen, setMetricsOpen] = useState(false);
  const [viewType, setViewType] = useState("table");
  const [breakpoint, setBreakpoint] = useState("desktop");
  const filteredItems = defaultCards.filter((c) =>
    filters ? filters.includes(c.status) : true
  );
  const [filterKey, setFilterKey] = useState("status");
  const [direction, setDirection] = useState(1);
  return (
    <>
      <HaBreakpointSensor
        breakpoints={[
          {
            name: "mobile",
            device: "screen",
            maxWidth: 800,
          },
          {
            name: "desktop",
            device: "screen",
            minWidth: 801,
          },
        ]}
        onHaBreakpointMatched={(e) => setBreakpoint(e.detail)}
      />
      {breakpoint === "desktop" && (
        <div
          style={
            {
              // transform: "rotateX(5deg) rotateY(1deg) rotateZ(-1deg)",
            }
          }
          className="ha-website-app-demo"
        >
          <HaThemeContext showBackground={false}>
            <div className="ha-display-flex ha-display-flex-row ha-display-fullwidth">
              <div ref={ref} style={{ width: "100%" }}>
                <HaBanner>
                  <HaIcon slot="icon" iconName={HarmonyIconNames.MouseSymbol} />
                  <span slot="title">
                    Click on the elements highlighted below to test drive some
                    of the components.
                  </span>
                </HaBanner>
                <HaSpacer size={HarmonySpacerSize.M} orientation="vertical" />
                <div className="ha-display-flex ha-display-flex-row ha-display-fullwidth">
                  <div
                    style={{ minWidth: "280px", width: "auto" }}
                    className="ha-display-inline-flex ha-display-flex-column"
                  >
                    <div className="ha-white-background ha-size-border-radius-8 ha-elevation-shadow-three ha-size-padding-16 ha-display-flex ha-display-flex-column">
                      <HaSelect
                        value={selectValue}
                        selectionType={HarmonySelectionType.Single}
                        showInputError={HarmonyInputErrorTypes.Inline}
                        onHaChange={(e) =>
                          setSelectValue((e.target as any).value)
                        }
                      >
                        <HaInput
                          fillStyle={HarmonyFillStyle.Ghost}
                          slot="select-target"
                        >
                          <div
                            slot="icon"
                            style={{ width: "24px", height: "24px" }}
                          >
                            <div
                              className={`${
                                selectValue === "ACME Inc"
                                  ? "ha-sunflower-background"
                                  : "ha-rose-background"
                              }`}
                              style={{
                                width: "24px",
                                height: "24px",
                                borderRadius: "2px",
                              }}
                            >
                              <HaIcon
                                size={24}
                                color={HarmonyColorName.White}
                                iconName={
                                  selectValue === "ACME Inc"
                                    ? HarmonyIconNames.A
                                    : HarmonyIconNames.Internet
                                }
                              />
                            </div>
                          </div>
                          <HaIcon
                            iconName={HarmonyIconNames.CaretDown}
                            slot="right"
                          />
                        </HaInput>
                        <HaPopover overlayClassname="ha-website-select-slanted">
                          <HaList>
                            <HaListItem value="ACME Inc">
                              <HaIcon
                                slot="left"
                                size={24}
                                color={HarmonyColorName.Sunflower}
                                iconName={HarmonyIconNames.A}
                              />
                              <span>ACME Inc</span>
                            </HaListItem>
                            <HaListItem value="McDowells">
                              <HaIcon
                                slot="left"
                                size={24}
                                color={HarmonyColorName.Rose}
                                iconName={HarmonyIconNames.Internet}
                              />
                              <span>McDowells</span>
                            </HaListItem>
                            <div slot="no-results">
                              <span className="ha-text-style-ghosted ha-body-text-size-m">
                                <span>
                                  No organization matches your search. Check
                                  with your administrator.
                                </span>
                              </span>
                            </div>
                          </HaList>
                        </HaPopover>
                      </HaSelect>
                      <HaSeparator orientation="horizontal" />
                      <HaTree scrollToOnLoad={false} fullWidth={true}>
                        <HaTreeItem
                          isOpen={true}
                          onHaItemSelect={(e) => {
                            e.stopPropagation();
                            (e.target as any).active = false;
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <HaIcon
                            slot="tree-icon"
                            size={24}
                            color={HarmonyColorType.Primary}
                            iconName={HarmonyIconNames.LightningBolt}
                          />
                          <HaSpacer
                            tree-icon
                            size={HarmonySpacerSize.Xs}
                            orientation="horizontal"
                          />
                          <span className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
                            <span className="ha-body-text-weight-semibold">
                              Activity
                            </span>
                            <HaSpacer
                              size={HarmonySpacerSize.S}
                              orientation="horizontal"
                            />
                            <HaTag
                              interactive={false}
                              colorType={HarmonyColorType.Primary}
                            >
                              2
                            </HaTag>
                          </span>
                          <HaTreeItem
                            active={true}
                            slot="tree-nesting"
                            onHaItemSelect={(e) => {
                              e.stopPropagation();
                              (e.target as any).active = true;
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Inbound
                          </HaTreeItem>
                          <HaTreeItem
                            slot="tree-nesting"
                            onHaItemSelect={(e) => {
                              e.stopPropagation();
                              (e.target as any).active = false;
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            Outbound
                          </HaTreeItem>
                        </HaTreeItem>
                        <HaTreeItem
                          onHaItemSelect={(e) => {
                            e.stopPropagation();
                            (e.target as any).active = false;
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <HaIcon
                            slot="tree-icon"
                            size={24}
                            color={HarmonyColorType.Constructive}
                            iconName={HarmonyIconNames.CircleCheckmark}
                          />
                          <HaSpacer
                            tree-icon
                            size={HarmonySpacerSize.Xs}
                            orientation="horizontal"
                          />
                          <span className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
                            <span className="ha-body-text-weight-semibold">
                              Tasks
                            </span>
                            <HaSpacer
                              size={HarmonySpacerSize.S}
                              orientation="horizontal"
                            />
                            <HaTag
                              interactive={false}
                              colorType={HarmonyColorType.Neutral}
                            >
                              0
                            </HaTag>
                          </span>
                          <HaTreeItem
                            slot="tree-nesting"
                            onHaItemSelect={(e) => {
                              e.stopPropagation();
                              (e.target as any).active = false;
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div
                              style={{ opacity: 0.5 }}
                              className="ha-body-text-size-m"
                            >
                              No tasks created.
                            </div>
                          </HaTreeItem>
                        </HaTreeItem>
                      </HaTree>
                    </div>
                  </div>
                  <HaSpacer
                    size={HarmonySpacerSize.M}
                    orientation="horizontal"
                  />
                  <div
                    style={{ overflow: "hidden", height: "460px" }}
                    className="ha-white-background ha-size-border-radius-8 ha-elevation-shadow-three ha-display-flex ha-display-flex-column ha-display-fullwidth"
                  >
                    <div
                      style={{ boxSizing: "border-box", zIndex: 1 }}
                      className="ha-size-border-radius-topleftradius-8 ha-size-border-radius-toprightradius-8 ha-elevation-shadow-two ha-size-padding-16  ha-display-flex ha-display-flex-column ha-display-fullwidth"
                    >
                      <div
                        style={{ boxSizing: "border-box" }}
                        className="ha-display-flex ha-display-flex-row ha-display-fullwidth ha-display-flex-align-items-center ha-display-flex-justify-content-spacebetween"
                      >
                        <h5 className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
                          Inbound Leads
                          <HaSpacer
                            size={HarmonySpacerSize.S}
                            orientation="horizontal"
                          />
                          <HaBadge
                            styleOverride={`@keyframes badgePulse {
                              0% {
                                outline: solid;
                                outline-offset: 0;
                                outline-width: 0;
                              }
                              50% {
                                outline: solid;
                                outline-offset: 4px;
                                outline-width: 10px;
                              }
                              100% {
                                outline: solid;
                                outline-offset: 0;
                                outline-width: 0;
                              }
                            } :host([fill-style="Solid"][color-type="Primary"][theme="light"]) .ha-badge-wrapper { outline-color: #3f20fd4D !important; animation: badgePulse 0.8s infinite linear; } :host([shape="Circular"][size="Normal"]) .ha-badge-wrapper { padding: 0 0 !important}`}
                          >
                            <HaButton
                              active={metricsOpen}
                              onHaClick={() => setMetricsOpen(!metricsOpen)}
                              size={HarmonySize.Small}
                              fillStyle={HarmonyFillStyle.Ghost}
                            >
                              <HaIcon
                                slot="left-icon"
                                iconName={
                                  metricsOpen
                                    ? HarmonyIconNames.ChevronUp
                                    : HarmonyIconNames.ChevronBottom
                                }
                              />
                            </HaButton>
                          </HaBadge>
                        </h5>
                        <div className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center ha-display-flex-justify-content-spacebetween">
                          <HaAvatar
                            size={HarmonySize.Small}
                            firstName="John"
                            lastName="Appleseed"
                            userId={"e82101f9-02cd-4494-89bc-974e8ecc4477"}
                          />
                          <HaSpacer
                            size={HarmonySpacerSize.Xs}
                            orientation="horizontal"
                          />
                          <HaAvatar
                            size={HarmonySize.Small}
                            firstName="Mary"
                            lastName="Pineapple"
                            userId={"f47d3525-eb3c-47a9-8856-65a20f1d9773"}
                          />
                          <HaSpacer
                            size={HarmonySpacerSize.S}
                            orientation="horizontal"
                          />
                          <HaButton fillStyle={HarmonyFillStyle.Ghost}>
                            <HaIcon
                              iconName={HarmonyIconNames.CirclePlus}
                              slot="left-icon"
                            />
                            <span>New lead</span>
                          </HaButton>
                        </div>
                      </div>
                      <HaAccordion isOpen={metricsOpen}>
                        <HaSpacer
                          size={HarmonySpacerSize.M}
                          orientation="vertical"
                        />
                        <div
                          style={{ gap: "16px" }}
                          className="ha-display-flex ha-display-flex-row"
                        >
                          <div
                            style={{ flex: 1 }}
                            className="ha-size-border-radius-6 ha-size-padding-16 ha-elevation-shadow-one ha-display-flex ha-display-flex-column"
                          >
                            <h6>Total</h6>
                            <HaSpacer
                              size={HarmonySpacerSize.S}
                              orientation="vertical"
                            />
                            <span className="ha-headings-text-size-l">
                              {defaultCards.length}
                            </span>
                          </div>
                          <div
                            style={{ flex: 1 }}
                            className="ha-size-border-radius-6 ha-size-padding-16 ha-elevation-shadow-one ha-display-flex ha-display-flex-column"
                          >
                            <h6>Winning</h6>
                            <HaSpacer
                              size={HarmonySpacerSize.S}
                              orientation="vertical"
                            />
                            <span className="ha-constructive-color ha-headings-text-size-l">
                              {
                                defaultCards.filter(
                                  (c) =>
                                    c.status === "Open" ||
                                    c.status === "Won" ||
                                    c.status === "Contract"
                                ).length
                              }
                            </span>
                          </div>
                          <div
                            style={{ flex: 1 }}
                            className="ha-size-border-radius-6 ha-size-padding-16 ha-elevation-shadow-one ha-display-flex ha-display-flex-column"
                          >
                            <h6>Losing</h6>
                            <HaSpacer
                              size={HarmonySpacerSize.S}
                              orientation="vertical"
                            />
                            <span className="ha-text-style-ghosted ha-headings-text-size-l">
                              {
                                defaultCards.filter(
                                  (c) =>
                                    c.status !== "Open" &&
                                    c.status !== "Won" &&
                                    c.status !== "Contract"
                                ).length
                              }
                            </span>
                          </div>
                        </div>
                      </HaAccordion>
                      <HaSpacer
                        size={HarmonySpacerSize.M}
                        orientation="vertical"
                      />
                      <div
                        style={{ boxSizing: "border-box" }}
                        className="ha-display-flex ha-display-flex-row ha-display-fullwidth ha-display-flex-align-items-center ha-display-flex-justify-content-spacebetween"
                      >
                        <HaBadge
                          className="ha-display-fullwidth"
                          styleOverride={`@keyframes badgePulse {
                            0% {
                              outline: solid;
                              outline-offset: 0;
                              outline-width: 0;
                            }
                            50% {
                              outline: solid;
                              outline-offset: 4px;
                              outline-width: 10px;
                            }
                            100% {
                              outline: solid;
                              outline-offset: 0;
                              outline-width: 0;
                            }
                          } :host([fill-style="Solid"][color-type="Primary"][theme="light"]) .ha-badge-wrapper { outline-color: #3f20fd4D !important; animation: badgePulse 0.8s infinite linear; } :host([shape="Circular"][size="Normal"]) .ha-badge-wrapper { padding: 0 0 !important}`}
                        >
                          <HaSelect
                            selectionType={HarmonySelectionType.Multiple}
                            shouldCloseOnSelect={false}
                            value={filters}
                            onHaChange={(e) =>
                              setFilters((e.target as any).value)
                            }
                          >
                            <HaTagInput
                              placeholder="Select filters..."
                              slot="select-target"
                              showClearAffordance={true}
                            >
                              <HaIcon
                                slot="icon"
                                iconName={HarmonyIconNames.Equalizer}
                              />
                            </HaTagInput>
                            <HaPopover
                              fullWidth={true}
                              overlayClassname="ha-website-select-slanted"
                            >
                              <HaList>
                                <HaListItem
                                  value="Open"
                                  properties={[
                                    { colorType: HarmonyColorType.Primary },
                                  ]}
                                >
                                  Open
                                </HaListItem>
                                <HaListItem
                                  value="Contract"
                                  properties={[
                                    { colorType: HarmonyColorType.Neutral },
                                  ]}
                                >
                                  Contract
                                </HaListItem>
                                <HaListItem
                                  value="Lost"
                                  properties={[
                                    {
                                      colorType: HarmonyColorType.Destructive,
                                    },
                                  ]}
                                >
                                  Lost
                                </HaListItem>
                                <HaListItem
                                  value="Won"
                                  properties={[
                                    {
                                      colorType: HarmonyColorType.Constructive,
                                    },
                                  ]}
                                >
                                  Won
                                </HaListItem>
                              </HaList>
                            </HaPopover>
                          </HaSelect>
                        </HaBadge>
                        <HaSpacer
                          size={HarmonySpacerSize.M}
                          orientation="horizontal"
                        />
                        <HaButton
                          active={viewType === "table"}
                          fillStyle={HarmonyFillStyle.Ghost}
                          colorType={HarmonyColorType.Neutral}
                          onHaClick={() => setViewType("table")}
                        >
                          <HaIcon
                            slot="left-icon"
                            iconName={HarmonyIconNames.Table}
                          />
                        </HaButton>
                        <HaBadge
                          styleOverride={`@keyframes badgePulse {
                            0% {
                              outline: solid;
                              outline-offset: 0;
                              outline-width: 0;
                            }
                            50% {
                              outline: solid;
                              outline-offset: 4px;
                              outline-width: 10px;
                            }
                            100% {
                              outline: solid;
                              outline-offset: 0;
                              outline-width: 0;
                            }
                          } :host([fill-style="Solid"][color-type="Primary"][theme="light"]) .ha-badge-wrapper { outline-color: #3f20fd4D !important; animation: badgePulse 0.8s infinite linear; } :host([shape="Circular"][size="Normal"]) .ha-badge-wrapper { padding: 0 0 !important}`}
                        >
                          <HaButton
                            active={viewType === "card"}
                            fillStyle={HarmonyFillStyle.Ghost}
                            colorType={HarmonyColorType.Neutral}
                            onHaClick={() => setViewType("card")}
                          >
                            <HaIcon
                              slot="left-icon"
                              iconName={HarmonyIconNames.JustifiedLines}
                            />
                          </HaButton>
                        </HaBadge>
                      </div>
                    </div>
                    <div
                      style={{
                        display: filteredItems.length === 0 ? "flex" : "",
                        alignItems: filteredItems.length === 0 ? "center" : "",
                        justifyContent:
                          filteredItems.length === 0 ? "space-around" : "",
                        height: `${
                          metricsOpen
                            ? "calc(460px - 228px)"
                            : "calc(460px - 112px)"
                        }`,
                      }}
                    >
                      {viewType === "table" && filteredItems.length !== 0 && (
                        <HaDisplayTable
                          className="ha-website-display-table-appear"
                          columnWidths={[
                            {
                              columnId: "status",
                              value: 16,
                              unit: "%",
                              sticky: true,
                            },
                            {
                              columnId: "company",
                              value: 19.5,
                              unit: "%",
                              sticky: true,
                            },
                            { columnId: "location", value: 18.5, unit: "%" },
                            { columnId: "design", value: 17, unit: "%" },
                            { columnId: "dev", value: 14.5, unit: "%" },
                            { columnId: "team", value: 14.5, unit: "%" },
                          ]}
                          size={HarmonySize.Normal}
                          style={{
                            width: "calc(100% + 1px)",
                            height: "calc(100% + 1px)",
                            borderRadius: "0",
                            boxSizing: "border-box",
                          }}
                        >
                          <HaDisplayTableBody>
                            <HaDisplayTableRow sticky={true} header={true}>
                              {Object.entries(filteredItems[0]).map((hv) => {
                                return (
                                  <HaDisplayTableCell columnId={hv[0]}>
                                    <div
                                      onClick={() => {
                                        if (hv[0] === filterKey) {
                                          setDirection(direction * -1);
                                        }
                                        setFilterKey(`${hv[0]}`);
                                      }}
                                      className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center"
                                    >
                                      <span
                                        className={`${
                                          hv[0] === filterKey
                                            ? "ha-display-flex ha-display-flex-row ha-display-flex-align-items-center ha-body-text-weight-bold ha-black-color"
                                            : ""
                                        }`}
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {hv[0]}
                                        {hv[0] === filterKey && (
                                          <>
                                            <HaSpacer
                                              size={HarmonySpacerSize.Xs}
                                              orientation="horizontal"
                                            />
                                            <HaIcon
                                              iconName={
                                                direction === 1
                                                  ? filterKey !== "team"
                                                    ? HarmonyIconNames.LettersSortedAscending
                                                    : HarmonyIconNames.NumbersSortedAscending
                                                  : filterKey !== "team"
                                                  ? HarmonyIconNames.LettersSortedDescending
                                                  : HarmonyIconNames.NumbersSortedDescending
                                              }
                                              size={16}
                                            />
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </HaDisplayTableCell>
                                );
                              })}
                            </HaDisplayTableRow>
                            {filteredItems
                              .sort((a: any, b: any) => {
                                return a[`${filterKey}`] > b[`${filterKey}`]
                                  ? 1 * direction
                                  : -1 * direction;
                              })
                              .map((card, i) => {
                                return (
                                  <HaDisplayTableRow>
                                    {Object.entries(card).map((en) => {
                                      return (
                                        <HaDisplayTableCell columnId={en[0]}>
                                          {en[0] === "status" ? (
                                            <HaTag
                                              interactive={false}
                                              size={HarmonySize.Normal}
                                              colorType={
                                                en[1] === "Open"
                                                  ? HarmonyColorType.Primary
                                                  : card.status === "Contract"
                                                  ? HarmonyColorType.Neutral
                                                  : card.status === "Won"
                                                  ? HarmonyColorType.Constructive
                                                  : HarmonyColorType.Destructive
                                              }
                                            >
                                              {en[1]}
                                            </HaTag>
                                          ) : (
                                            <div className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
                                              {en[0] !== "status" &&
                                                en[0] !== "company" && (
                                                  <>
                                                    <HaIcon
                                                      iconName={
                                                        en[0] === "location"
                                                          ? HarmonyIconNames.LocationPin
                                                          : en[0] === "design"
                                                          ? HarmonyIconNames.Paintbrush
                                                          : en[0] === "dev"
                                                          ? HarmonyIconNames.CodeSymbol
                                                          : HarmonyIconNames.People
                                                      }
                                                      size={16}
                                                    />
                                                    <HaSpacer
                                                      size={
                                                        HarmonySpacerSize.Xs
                                                      }
                                                      orientation="horizontal"
                                                    />
                                                  </>
                                                )}
                                              <span>{en[1]}</span>
                                            </div>
                                          )}
                                        </HaDisplayTableCell>
                                      );
                                    })}
                                  </HaDisplayTableRow>
                                );
                              })}
                          </HaDisplayTableBody>
                        </HaDisplayTable>
                      )}
                      {viewType === "card" && filteredItems.length !== 0 && (
                        <HaScrollArea
                          className="ha-website-demo-scroll-area"
                          style={{ display: "inline-flex", height: "100%" }}
                        >
                          <div
                            style={{ boxSizing: "border-box" }}
                            className={`${
                              filteredItems.length === 0
                                ? "ha-display-flex-justify-content-spacearound"
                                : ""
                            } ha-display-fullwidth ha-size-padding-16 ha-display-flex ha-display-flex-column ha-display-flex-align-items-center`}
                          >
                            {filteredItems.map((card, i) => {
                              return (
                                <>
                                  <div
                                    style={{ boxSizing: "border-box" }}
                                    className="ha-display-flex ha-display-flex-row ha-display-fullwidth ha-size-padding-16 ha-size-border-radius-4 ha-elevation-shadow-one"
                                  >
                                    <div
                                      style={{ flexGrow: 1 }}
                                      className="ha-display-flex ha-display-flex-column"
                                    >
                                      <h6 className="ha-display-flex ha-display-flex-row">
                                        {card.company}
                                        <HaSpacer
                                          size={HarmonySpacerSize.S}
                                          orientation="horizontal"
                                        />
                                        <HaTag
                                          interactive={false}
                                          colorType={
                                            card.status === "Open"
                                              ? HarmonyColorType.Primary
                                              : card.status === "Contract"
                                              ? HarmonyColorType.Neutral
                                              : card.status === "Won"
                                              ? HarmonyColorType.Constructive
                                              : HarmonyColorType.Destructive
                                          }
                                        >
                                          <span className="ha-body-text">
                                            {card.status}
                                          </span>
                                        </HaTag>
                                      </h6>
                                      <HaSpacer
                                        size={HarmonySpacerSize.M}
                                        orientation="vertical"
                                      />
                                      <div className="ha-display-flex ha-display-flex-row">
                                        <div className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
                                          <HaIcon
                                            iconName={
                                              HarmonyIconNames.LocationPin
                                            }
                                            size={16}
                                          />
                                          <HaSpacer
                                            size={HarmonySpacerSize.Xs}
                                            orientation="horizontal"
                                          />
                                          <span className="ha-text-style-ghosted ha-body-text-weight-semibold ha-body-text-size-s">
                                            {card.location}
                                          </span>
                                        </div>
                                        <HaSpacer
                                          size={HarmonySpacerSize.S}
                                          orientation="horizontal"
                                        />
                                        <div className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
                                          <HaIcon
                                            iconName={
                                              HarmonyIconNames.Paintbrush
                                            }
                                            size={16}
                                          />
                                          <HaSpacer
                                            size={HarmonySpacerSize.Xs}
                                            orientation="horizontal"
                                          />
                                          <span className="ha-text-style-ghosted ha-body-text-weight-semibold ha-body-text-size-s">
                                            {card.design}
                                          </span>
                                        </div>
                                        <HaSpacer
                                          size={HarmonySpacerSize.S}
                                          orientation="horizontal"
                                        />
                                        <div className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
                                          <HaIcon
                                            iconName={
                                              HarmonyIconNames.CodeSymbol
                                            }
                                            size={16}
                                          />
                                          <HaSpacer
                                            size={HarmonySpacerSize.Xs}
                                            orientation="horizontal"
                                          />
                                          <span className="ha-text-style-ghosted ha-body-text-weight-semibold ha-body-text-size-s">
                                            {card.dev}
                                          </span>
                                        </div>
                                        <HaSpacer
                                          size={HarmonySpacerSize.S}
                                          orientation="horizontal"
                                        />
                                        <div className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
                                          <HaIcon
                                            iconName={HarmonyIconNames.People}
                                            size={16}
                                          />
                                          <HaSpacer
                                            size={HarmonySpacerSize.Xs}
                                            orientation="horizontal"
                                          />
                                          <span className="ha-text-style-ghosted ha-body-text-weight-semibold ha-body-text-size-s">
                                            {card.team}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="ha-display-flex ha-display-flex-column ha-display-flex-align-items-flexstart">
                                      <HaButton
                                        size={HarmonySize.Small}
                                        fillStyle={HarmonyFillStyle.Ghost}
                                      >
                                        <HaIcon
                                          size={24}
                                          slot="left-icon"
                                          iconName={
                                            HarmonyIconNames.DotsHorizontal
                                          }
                                        />
                                      </HaButton>
                                    </div>
                                  </div>
                                  {i !== filteredItems.length - 1 && (
                                    <HaSpacer
                                      size={HarmonySpacerSize.S}
                                      orientation="vertical"
                                    />
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </HaScrollArea>
                      )}
                      {filteredItems.length === 0 && (
                        <HaInfoDisplay size={HarmonySize.Normal}>
                          <HaIcon
                            size={48}
                            slot="decoration"
                            iconName={HarmonyIconNames.Clean}
                          />
                          <span slot="title">No leads match this filter</span>
                          <span slot="description">
                            Try adjusting your filters.
                          </span>
                        </HaInfoDisplay>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </HaThemeContext>
        </div>
      )}
    </>
  );
}
