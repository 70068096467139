import editor from "../resources/Editor@2x.svg";
import design from "../resources/Design@2x.svg";
import develop from "../resources/Develop@2x.svg";
import extend from "../resources/Extend@2x.svg";

export const carouselProps = {
  slides: [
    {
      title: "Your Own Design System, No Code Required",
      rotation: 2,
      description:
        "Use the Editor to define tokens and design components and generate the Figma assets and code.",
      image: editor,
    },
    {
      title: "Keep Your Designs And Code In Sync",
      rotation: -2,
      description:
        "Design everything in Figma using the same components that developers use in production.",
      image: design,
    },
    {
      title: "Streamline Development",
      rotation: 2,
      description:
        "Focus on building your product without worrying about conflicting APIs, accessibility, or broken hand offs.",
      image: develop,
    },
    {
      title: "Never Get Stuck, Keep Extending",
      rotation: -3,
      description:
        "Design and build new components quickly by leveraging the existing building blocks in your design system.",
      image: extend,
    },
  ],
};

export function preLoadImages() {
  carouselProps.slides.forEach((prop) => {
    const img = new Image();
    img.src = prop.image;
  });
}
