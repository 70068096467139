import { Star } from "../../decoration/star/Star";
import Editor from "../../carousel/resources/Editor@2x.svg";
import Design from "../../carousel/resources/Design@2x.svg";
import Develop from "../../carousel/resources/Develop@2x.svg";
import Documentation from "../../carousel/resources/Documentation@2x.svg";
import { RequestAccess } from "../../request/RequestAccess";

export function Vision(props: {
  onRequestAccess: () => void;
  onSuccessForm: () => void;
}) {
  return (
    <>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <h1 className="web ha-website-h1-blog ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <span>Building Design Systems</span>
        <Star
          shape="stop"
          className="ha-website-hero-blog-star"
          content={<h3 className="center ha-website-white">STOP</h3>}
          size={36}
          color="#FF0000"
        />
      </h1>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
        The value design systems create is well understood - designers can
        create better product interfaces, development can move faster, and
        ultimately teams can ship better products. While top companies all
        maintain design systems, most product teams face a dilemma: settle for
        component libraries that are disconnected from design and limited in
        functionality and extensibility, or spend thousands of designer and
        developer hours to build one from scratch.
        <br />
        <br />
        While building design systems and delivering successful products at
        companies such as Palantir, Meta, Checkout.com, and Thomson Reuters, we
        realized we could enable the best of both worlds — the speed of a
        component library with the flexibility of a bespoke design system.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        <h2 className="web">A New Way of Building Products</h2>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        Blocksfabrik is the only platform that lets you create, maintain, and
        extend design systems without writing any code. <br />
        <br />
        As the single source of truth across Figma, component code, and
        documentation - Blocksfabrik is uniquely positioned to bridge the gap
        between designers and developers, streamline collaboration, and
        transform how products are built.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        <h2 className="web">How It Works?</h2>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        You're in the driver's seat - but we automate all the hard work. Let's
        take a closer look:
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        <h4 className="web">Craft Your Own Design System</h4>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        Use our editor to set up your design system: typography, colors, sizes,
        shapes, behaviors can all be customized and extended to any level of
        detail for every component. This is your source of truth.
        <br />
        <br />
        When you're ready, publish your design system to generate all of your
        component code, Figma assets, and documentation.
        <br />
        <br />
        If you're in a hurry and want to start building, you can always start
        with our default design system and make it yours later.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4">
        <div className="ha-website-carousel-image-container">
          <img className="modifier-2" src={Editor} alt={Editor} />
        </div>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        <h4 className="web">Design Your Product</h4>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        Our Figma plugin provides direct access to the tokens and components you
        created in the editor. You can choose which variant of each component
        you'd like to add by simply dragging and dropping from the assets panel.
        Because components match the interface exposed in code, you are
        guaranteed Figma and code will now match 1:1.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4">
        <div className="ha-website-carousel-image-container">
          <img className="modifier-1" src={Design} alt={Design} />
        </div>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        <h4 className="web">Start Developing</h4>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        Since the components have been defined as part of the design system and
        the code is generated for you, you don't have to worry about writing any
        of the component functionality yourself.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4">
        <div className="ha-website-carousel-image-container">
          <img className="modifier-2" src={Develop} alt={Develop} />
        </div>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        <h4 className="web">Everything Documented For You</h4>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        We've heard over and over again how quickly documentation falls out of
        sync. So we decided to generate the documentation for you. Examples and
        technical documentation for props, slots, and methods will consistently
        reflect each version of your design system.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4">
        <div className="ha-website-carousel-image-container">
          <img className="modifier-1" src={Documentation} alt={Documentation} />
        </div>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        <h2 className="web">Let's chat</h2>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-4 ha-website-text-content">
        Are you considering a design system but worried about the massive cost,
        struggling to extend your current one or finding that the components you
        are using are limiting? If so please fill out the form below, we'd love
        to chat!
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <RequestAccess
        inline={true}
        onClose={() => {}}
        onSuccessForm={() => props.onSuccessForm()}
      />
    </>
  );
}
