import EnterpriseWhite from "../resources/EnterpriseWhite.svg";
import Editor from "../../../carousel/resources/Develop@2x.svg";
import { Demo } from "../../vision/Demo";

export const enterpriseProps = {
  type: "Enterprise",
  title: `Create And Manage Design Systems At Scale`,
  description: `Shrink the cost of building and maintaining a design system from thousands of designer and developer hours, down to an affordable subscription.`,
  takeAways: [
    `Improve Design Developer Collaboration`,
    `Get A Single Source Of Truth For Your Design System`,
    `Advanced Role Definition And User Permissions`,
    `Integrates With The Tools Your Teams Already Use`,
  ],
  image: (
    <img
      width={160}
      style={{ transform: "rotate(-5deg)" }}
      alt="Enterprise"
      src={EnterpriseWhite}
    />
  ),
  problemDescription: `Expensive resources are busy maintaining and scaling the design system instead of focusing on the product.`,
  solutionDescription: `Free up valuable resources in your design and development organizations to deliver more business value.`,
  problems: [
    `Design and code are always out of sync`,
    `Resources blocked by maintenance`,
    `Refactoring required often`,
    `Slows you down`,
    `Requires code to extend`,
    `Poor accessibility support`,
    `Very expensive`,
    `Support requires internal resources`,
    `Hard to support multiple brands`,
    `Framework specific`,
  ],
  solutions: [
    `Design and code stay in sync`,
    `Resources can focus on the product`,
    `Extensible composable components`,
    `Enables high velocity`,
    `Extends without code`,
    `Accessibility built-in`,
    `Affordable subscription`,
    `Enterprise-grade support`,
    `Multi-brand support with ease`,
    `Framework agnostic`,
  ],
  keyMessageTitle: `Start With The Right Tools`,
  keyMessage: `Avoid the sunken cost of typical off-the-shelf libraries and start building your product the right way from day one.`,
  messages: [
    // {
    //   title: `Accelerate Out Of The Box, Make It Your Own Later`,
    //   description: `Start with the default configuration and effortlessly update to your design later with a simple version bump.`,
    // },
    {
      title: `Extensible Best In Class Components`,
      description: `Put composable building blocks together with ease to create completely new ones.`,
    },
    {
      title: `No More Maintenance Work`,
      description: `Stay focused on your product knowing that components are maintained for you.`,
    },
  ],
  //   hero: <img src={Editor} alt={Editor} />,
  hero: <Demo />,
  CTA: `Contact Sales`,
};
