export const componentProps = {
    components: [
        {
            name: "Button"
        },
        {
            name: "Input"
        },
        {
            name: "Date Picker"
        },
        {
            name: "Icon"
        },
        {
            name: "Slider"
        },
        {
            name: "Date Input"
        },
        {
            name: "Single Select"
        },
        {
            name: "Overlay"
        },
        {
            name: "Confirmation"
        },
        {
            name: "Dialog"
        },
        {
            name: "Avatar"
        },
        {
            name: "Control Group"
        },
        {
            name: "Banner"
        },
        {
            name: "Drawer"
        },
        {
            name: "Intro"
        },
        {
            name: "Menu"
        },
        {
            name: "Scroll Area"
        },
        {
            name: "Tag Input"
        },
        {
            name: "Grid"
        },
        {
            name: "Field"
        },
        {
            name: "Checkbox"
        },
        {
            name: "Accordion"
        },
        {
            name: "Context Menu"
        },
        {
            name: "CSS Utils"
        },
        {
            name: "Date Range Picker"
        },
        {
            name: "Info Display"
        },
        {
            name: "Keyboard Shortcut"
        },
        {
            name: "List"
        },
        {
            name: "Date Range Input"
        },
        {
            name: "Popover"
        },
        {
            name: "Radio"
        },
        {
            name: "Numeric Input"
        },
        {
            name: "Progress"
        },
        {
            name: "Text Tooltip"
        },
        {
            name: "Switch"
        },
        {
            name: "Color Picker"
        },
        {
            name: "Error Display"
        },
        {
            name: "Resize Panel"
        },
        {
            name: "Hint Display"
        },
        {
            name: "Form"
        },
        {
            name: "Radio Group"
        },
        {
            name: "Separator"
        },
        {
            name: "Tabs"
        },
        {
            name: "Text Area"
        },
        {
            name: "Link"
        },
        {
            name: "Badge"
        },
        {
            name: "Tag"
        },
        {
            name: "Tree"
        },
        {
            name: "Toast"
        },
        {
            name: "Spacer"
        },
        {
            name: "Theme Context"
        },
        {
            name: "Overflow"
        },
        {
            name: "Tooltip"
        },
        {
            name: "Flex Utils"
        },
        {
            name: "Time Input"
        },
        {
            name: "Card"
        },
        {
            name: "File Upload"
        },
        {
            name: "Timeline"
        },
        {
            name: "Breakpoint Sensor"
        },
        {
            name: "Table"
        },
        {
            name: "Wizard",
            completion: 25,
        },
        {
            name: "Color Input",
            completion: 10,
        },
        {
            name: "Chrome",
            completion: 15,
        },
        {
            name: "Layout",
            completion: 25,
        },
        {
            name: "Custom Components",
            completion: 25,
        },
    ]
}