import { useEffect, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";

export function Box(props: { position: any; color: any }) {
  const ref = useRef<THREE.Mesh>(null!);
  let item = 4;
  let xStart: number;
  let yStart: number;
  let zStart: number;
  useEffect(() => {
    xStart = Math.random() * 10;
    yStart = Math.random() * 10;
    zStart = Math.random() * 10;
    ref.current.rotation.x = xStart;
    ref.current.rotation.y = yStart;
    ref.current.rotation.z = zStart;
  }, []);
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 40;
    ref.current.rotation.y += delta / 40;
    ref.current.rotation.z += delta / 40;
  });
  return (
    <mesh {...props} ref={ref}>
      <boxGeometry args={[item, item, item]} />
      <meshStandardMaterial color={props.color} />
    </mesh>
  );
}

export function Dodeca(props: { position: any; color: any }) {
  const ref = useRef<THREE.Mesh>(null!);
  let item = 4;
  useEffect(() => {
    item = [1][Math.floor(Math.random() * [1].length)];
  }, []);
  useFrame((state, delta) => {
    ref.current.rotation.x += delta / 40;
    ref.current.rotation.y += delta / 40;
    ref.current.rotation.z -= delta / 40;
  });
  return (
    <mesh {...props} ref={ref}>
      <octahedronGeometry args={[3, 1]} />
      <meshStandardMaterial flatShading={true} color={props.color} />
    </mesh>
  );
}

export function Sphere(props: { position: any; color: any }) {
  const ref = useRef<THREE.Mesh>(null!);
  let item = 4;
  useEffect(() => {
    item = [1][Math.floor(Math.random() * [1].length)];
  }, []);
  useFrame((state, delta) => {
    ref.current.rotation.x += delta / 40;
    ref.current.rotation.y += delta / 40;
    ref.current.rotation.z += delta / 40;
  });
  return (
    <mesh {...props} ref={ref}>
      <sphereGeometry args={[3, 24, 24]} />
      <meshStandardMaterial flatShading={true} color={props.color} />
    </mesh>
  );
}

export function Icosa(props: { position: any; color: any }) {
  const ref = useRef<THREE.Mesh>(null!);
  let item = 4;
  useEffect(() => {
    item = [1][Math.floor(Math.random() * [1].length)];
  }, []);
  useFrame((state, delta) => {
    ref.current.rotation.x += delta / 40;
    ref.current.rotation.y -= delta / 40;
    ref.current.rotation.z += delta / 40;
  });
  return (
    <mesh {...props} ref={ref}>
      <icosahedronGeometry args={[2.75, 0]} />
      <meshStandardMaterial flatShading={true} color={props.color} />
    </mesh>
  );
}

export function Plus(props: { position: any; color: any }) {
  const ref1 = useRef<THREE.Mesh>(null!);
  const ref2 = useRef<THREE.Mesh>(null!);
  let item = 4;
  useEffect(() => {
    item = [1][Math.floor(Math.random() * [1].length)];
  }, []);
  useFrame((state, delta) => {
    ref1.current.rotation.z += delta / 40;
    ref1.current.rotation.y += delta / 400;
    ref1.current.rotation.x += delta / 4000;
    // ref1.current.rotation.y += delta / 40;
    // ref1.current.rotation.z += delta / 40;
    ref2.current.rotation.z += delta / 40;
    ref2.current.rotation.y += delta / 400;
    ref2.current.rotation.x += delta / 4000;
    // ref2.current.rotation.y += delta / 40;
    // ref2.current.rotation.z += delta / 40;
  });
  return (
    <>
      <mesh {...props} ref={ref1}>
        <boxGeometry args={[4, 2, 2]} />
        <meshStandardMaterial flatShading={true} color={props.color} />
      </mesh>
      <mesh {...props} ref={ref2}>
        <boxGeometry args={[2, 4, 2]} />
        <meshStandardMaterial flatShading={true} color={props.color} />
      </mesh>
    </>
  );
}

export function CanvasDemo(props: {
  color: any;
  type: "feature" | "star" | "stop" | "plus" | undefined;
  onVisible?: () => void;
}) {
  const [onScreen, setOnScreen] = useState(false);
  const canvasRef = useRef<any>(null!);
  let intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach(
      (entry) => {
        if (entry.isIntersecting && !onScreen) {
          setOnScreen(true);
          if (props.onVisible) {
            props.onVisible();
          }
        }
      },
      { root: document.body, threshold: 0.1 }
    );
  });
  useEffect(() => {
    intersectionObserver.observe(canvasRef.current);
  });
  return (
    <div
      ref={canvasRef}
      className="ha-website-3d-canvas"
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: props.type !== "plus" ? "115%" : "80%",
        height: props.type !== "plus" ? "115%" : "80%",
      }}
    >
      <Canvas
        className="ha-website-3d-canvas-animate-into-view"
        style={{
          display: !onScreen ? "none" : "",
        }}
      >
        <ambientLight intensity={0.75} />
        <spotLight
          intensity={0.5}
          position={[10, 100, 10]}
          angle={0.15}
          penumbra={1}
        />
        {/* <pointLight position={[-10, -10, -10]} /> */}
        {!props.type && <Box position={[0, 0, 0]} color={props.color} />}
        {props.type === "plus" && (
          <Plus position={[0, 0, 0]} color={props.color} />
        )}
        {props.type === "feature" && (
          <Sphere position={[0, 0, 0]} color={props.color} />
        )}
        {props.type === "star" && (
          <Dodeca position={[0, 0, 0]} color={props.color} />
        )}
        {props.type === "stop" && (
          <Icosa position={[0, 0, 0]} color={props.color} />
        )}
      </Canvas>
    </div>
  );
}
