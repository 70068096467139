import { HarmonyColorType, HarmonySize, HarmonyTheme } from "@blocksfabrik/harmony-core";
import { HaButton } from "@blocksfabrik/harmony-react";
import { useNavigate } from "react-router-dom";
import { Star } from "../../decoration/star/Star";
import { navigateLink } from "../../utils/navigateLogic";
import { SocialProof } from "../homepage/components/SocialProof";
import Gaingels from "./resources/Gaingels.png";
import Surface from "./resources/Surface.png";
import YCombinator from "./resources/YCombinator.png";

const team = [
  {
    name: "Alex Antihi",
    role: "CEO/Founder",
    linkedin: "https://www.linkedin.com/in/alexandruantihi",
  },
  {
    name: "Ryan Xie",
    role: "CPO/Cofounder",
    linkedin: "https://www.linkedin.com/in/ryan-xie-0208b46",
  },
  {
    name: "Vlad Soroceanu",
    role: "Founding Engineer",
    linkedin: "https://uk.linkedin.com/in/vladsoroceanu",
  },
  {
    name: "Miodrag Petrovici",
    role: "Senior Engineer",
    linkedin: "https://ro.linkedin.com/in/miodragpetrovici",
  },
  {
    name: "Bogdan Cojocariu",
    role: "Senior Engineer",
    linkedin: "https://ro.linkedin.com/in/bcojocariu",
  },
  {
    name: "Gilad Gray",
    role: "Advisor",
    linkedin: "https://www.linkedin.com/in/giladgray/",
  },
  {
    name: "Cenk Sezgin",
    role: "Advisor",
    linkedin: "https://www.linkedin.com/in/cenksezgin/",
  },
];
export function About() {
  const navigate = useNavigate();
  const renderTeamMember = (
    index: number,
    name: string,
    role: string,
    linkedin?: string
  ) => {
    return (
      <div
        key={"team" + index}
        className={`ha-display-flex ha-display-flex-column ha-display-flex-align-items-flexstart ha-website-grid-column-start-${
          (index % 4) + 1
        } ha-website-grid-column-end-${(index % 4) + 1}`}
      >
        <h5 className="web ha-website-white">
          {name.split(" ").map((n, i) => {
            return (
              <>
                {n}
                {i === 0 && <br />}
              </>
            );
          })}
        </h5>
        <div className="ha-website-spacer-vertical-xsmall" />
        <div className="ha-website-text-content ha-website-white small">
          {role}
        </div>
        {linkedin && (
          <>
            <div className="ha-website-spacer-vertical-xsmall" />
            <a
              className="ha-website-linkedin-logo"
              href={linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 3.5C5 4.9 3.9 6 2.5 6C1.1 6 0 4.9 0 3.5C0 2.1 1.1 1 2.5 1C3.9 1 5 2.1 5 3.5ZM5 8H0V24H5V8ZM13 8H8V24H13V15.6C13 10.9 19 10.5 19 15.6V24H24V13.9C24 6 15.1 6.3 13 10.2V8Z"
                  fill="white"
                />
              </svg>
            </a>
          </>
        )}
        <div className="ha-website-spacer-vertical-medium" />
      </div>
    );
  };

  return (
    <>
      <div className="ha-website-page-appear ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <h2 className="web ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        Building The Product Design Stack Of Tomorrow
      </h2>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
        We started with design systems. Find out more about the new standard for
        creating design systems.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <HaButton
          theme={HarmonyTheme.dark}
          size={HarmonySize.Large}
          colorType={HarmonyColorType.Primary}
          onHaClick={(e) => navigateLink(e, "/vision", navigate)}
        >
          <span className="ha-headings-text ha-headings-text-weight-semibold">
            Read About Our Vision
          </span>
        </HaButton>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
      <h1 className="web ha-website-white ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        Our
        <br />
        Team
        <Star
          className="ha-website-hero-features-star"
          content={<h4 className="web ha-website-white">Great</h4>}
          size={12}
          color="#3F20FD"
        />
      </h1>
      <SocialProof />
      {/* <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium' /> */}
      {team.map((t, i) => {
        return renderTeamMember(i, t.name, t.role, t.linkedin);
      })}
      <div className="ha-display-flex ha-display-flex-column ha-website-grid-column-start-1 ha-website-grid-column-end-2"></div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <h1 className="web ha-website-white ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        Our
        <br />
        Investors
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
        {/* <Star
          className="ha-website-hero-features-star"
          content={<h4 className="web ha-website-white">Amazing</h4>}
          size={14}
          color="#3F20FD"
        /> */}
      </h1>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-investor-logos ha-display-flex ha-display-flex-row ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <div className="ha-website-investor-logo">
          <img alt="YCombinator logo" src={YCombinator} />
        </div>
        <div className="ha-website-investor-logo">
          <img alt="Surface logo" src={Surface} />
        </div>
        <div className="ha-website-investor-logo">
          <img alt="Gaingels logo" src={Gaingels} />
        </div>
      </div>
    </>
  );
}
