import { HarmonyColorType, HarmonySize } from "@blocksfabrik/harmony-core";
import { HaButton } from "@blocksfabrik/harmony-react";
import { useNavigate } from "react-router-dom";
import { Star } from "../../../decoration/star/Star";
import { navigateLink } from "../../../utils/navigateLogic";
import "./scss/BlogFeature.scss";

export function BlogFeature() {
  const navigateTo = useNavigate();
  return (
    <>
      <h1 className="web ha-website-h1-blog ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <span>Building Design Systems</span>
        <Star
          shape="stop"
          className="ha-website-hero-blog-star"
          content={<h3 className="web center ha-website-white">STOP</h3>}
          size={36}
          color="#FF0000"
        />
      </h1>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
        Blocksfabrik is the only platform that lets you create and maintain
        complete design systems without writing any code. As the single source
        of truth across Figma, component code, and documentation - Blocksfabrik
        is uniquely positioned to help bridge the gap between designers and
        developers, streamline collaboration and transform how products are
        built.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
        <HaButton
          size={HarmonySize.Large}
          colorType={HarmonyColorType.Primary}
          onHaClick={(e) => navigateLink(e, `/vision`, navigateTo)}
        >
          <span className="ha-body-text-weight-medium">
            Read About Our Vision
          </span>
        </HaButton>
      </div>
    </>
  );
}
