import './scss/Components.scss';

export function Components(
    props: {
        components: {
            name: string,
            completion?: number;
            coming?: string
        }[];
    }
) {
    const renderComponent = (name: string, completion?: number, coming?: string, key?: string) => {
        return (
            <div key={key} className='ha-website-component-tag'>
                <span>{name}</span>
                <div style={{ opacity: completion !== undefined ? 1 : 1, width: `${completion !== undefined ? completion : 100}%`}} className='ha-website-component-tag-progress' />
            </div>
        )
    }
    return <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-components'>
        {props.components.map((component, i) => renderComponent(component.name, component.completion, component.coming, component.name + i))}
    </div>
}