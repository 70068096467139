import Airbus from '../resources/Airbus.svg';
import Checkout from '../resources/Checkout.svg';
import Mosaic from '../resources/Mosaic.svg';
import Ferrari from '../resources/Ferrari.svg';
import Teradata from '../resources/Teradata.svg';
import Palantir from '../resources/Palantir.svg';
import TwoSigma from '../resources/TwoSigma.svg';
import Meta from '../resources/Meta.svg';

export function SocialProof() {
    return (
        <>
            <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large' />
                <div
                    className='ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-text-content'
                >
                    Our team has designed and built products for some of the worlds greatest organizations
                </div>
                <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                <div
                    className='ha-website-grid-column-start-1 ha-website-grid-column-end-2'
                >
                    <img alt="Meta" src={Meta} />
                    <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                </div>
                <div
                    className='ha-website-grid-column-start-2 ha-website-grid-column-end-3'
                >
                    <img alt="Airbus" src={Airbus} />
                    <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                </div>
                <div
                    className='ha-website-grid-column-start-3 ha-website-grid-column-end-4'
                >
                    <img alt="TwoSigma" src={TwoSigma} />
                    <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                </div>
                <div
                    className='ha-website-grid-column-start-4 ha-website-grid-column-end-5'
                >
                    <img alt="Checkout" src={Checkout} />
                    <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                </div>
                <div
                    className='ha-website-grid-column-start-1 ha-website-grid-column-end-2'
                >
                    <img alt="Palantir" src={Palantir} />
                    <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                </div>
                <div
                    className='ha-website-grid-column-start-2 ha-website-grid-column-end-3'
                >
                    <img alt="Ferrari"src={Ferrari} />
                    <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                </div>
                <div
                    className='ha-website-grid-column-start-3 ha-website-grid-column-end-4'
                >
                    <img alt="Mosaic" src={Mosaic} />
                    <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                </div>
                <div
                    className='ha-website-grid-column-start-4 ha-website-grid-column-end-5'
                >
                    <img alt="Teradata" src={Teradata} />
                    <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small' />
                </div>
                <div className='ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large' />
        </>
    )
}