import GrowthWhite from "../resources/GrowthWhite.svg";
import Editor from "../../../carousel/resources/Develop@2x.svg";
import { Demo } from "../../vision/Demo";

export const growthStartupProps = {
  type: "Growth Startups",
  title: `Leave Your Limitations Behind`,
  description: `Your setup has turned into a liability, and your designers and engineers are burning cycles shipping custom components. Upgrade to a design system, without suffering the cost of building one from scratch.`,
  takeAways: [
    `Match Your Existing Style With Ease For A Seamless Transition`,
    `Move At You Own Pace And Swap Out Incrementally`,
    `Easy To Configure, Limitless Extensibility`,
    `Works With Major Frameworks Or No Framework At All`,
  ],
  image: (
    <img
      width={160}
      style={{ transform: "rotate(-5deg)" }}
      alt="Growth"
      src={GrowthWhite}
    />
  ),
  problemDescription: `You're stuck accumulating and paying off debt because you always have to add that one extra component feature.`,
  solutionDescription: `Move forward with the support of an entire extra design and engineering team built into your Blocksfabrik subscription.`,
  problems: [
    `Design and code are always out of sync`,
    `Negatively impacts resourcing`,
    `Refactoring required often`,
    `Slows you down`,
    `Requires code to extend`,
    `Poor accessibility support`,
    `Very expensive`,
    `Framework specific`,
  ],
  solutions: [
    `Design and code stay in sync`,
    `Empowers current resources`,
    `Extensible composable components`,
    `Enables high velocity`,
    `Extends without code`,
    `Accessibility built-in`,
    `Affordable subscription`,
    `Framework agnostic`,
  ],
  keyMessageTitle: `Start With The Right Tools`,
  keyMessage: `Avoid the sunken cost of typical off-the-shelf libraries and start building your product the right way from day one.`,
  messages: [
    // {
    //   title: `Accelerate Out Of The Box, Make It Your Own Later`,
    //   description: `Start with the default configuration and effortlessly update to your design later with a simple version bump.`,
    // },
    {
      title: `Extensible Best In Class Components`,
      description: `Put composable building blocks together with ease to create completely new ones.`,
    },
    {
      title: `No More Maintenance Work`,
      description: `Stay focused on your product knowing that components are maintained for you.`,
    },
  ],
  //   hero: <img src={Editor} alt={Editor} />,
  hero: <Demo />,
};
