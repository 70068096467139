export function navigateLink(e: any, route: string, navigateFunction: (route: string) => void) {
    e.preventDefault();
    const content = document.querySelector('.ha-website-content');
    content?.classList.add('ha-website-content-animate');
    setTimeout(() => {
        window.scrollTo({ top: 0});
        navigateFunction(`${route}`);
    }, 150);
    setTimeout(() => {
        content?.classList.remove('ha-website-content-animate');
    }, 300)
}