import StartupWhite from "../resources/StartupsWhite.svg";
import Editor from "../../../carousel/resources/Editor@2x.svg";
import { Demo } from "../../vision/Demo";

export const earlyStartupProps = {
  type: "Early Startups",
  title: `Design & Build Fast, Skip the Tech Debt`,
  description: `The speed of using a component library, the flexibility of a bespoke design system, and none of the tech debt. Get up and running in no time with everything you need and focus on building the best product for your users.`,
  takeAways: [
    `Built-in Rails To Design Better Products`,
    `Move Fast With 50+ Components Out Of The Box`,
    `Easy To Configure, Limitless Extensibility`,
    `Works With Major Frameworks Or No Framework At All`,
  ],
  image: (
    <img
      width={160}
      style={{ transform: "rotate(-5deg)" }}
      alt="Startup"
      src={StartupWhite}
    />
  ),
  problemDescription: `You're wrangling multiple libraries that were not made to do what you want them to do.`,
  solutionDescription: `Ship the product you want with design and front-end components empowering you along the way.`,
  problems: [
    `Design and code are always out of sync`,
    `Months to build everything internally`,
    `Limiting black box components`,
    `Conflicting component APIs`,
    `Off brand`,
    `Clunky products`,
    `Very expensive`,
    `Framework specific`,
  ],
  solutions: [
    `Design and code stay in sync`,
    `Get started in as little as a few minutes`,
    `Extensible composable components`,
    `Unified components API`,
    `Matches any brand`,
    `Delightful experiences`,
    `Affordable subscription`,
    `Framework agnostic`,
  ],
  keyMessageTitle: `Start With The Right Tools`,
  keyMessage: `Avoid the sunken cost of typical off-the-shelf libraries and start building your product the right way from day one.`,
  messages: [
    // {
    //   title: `Accelerate Out Of The Box, Make It Your Own Later`,
    //   description: `Start with the default configuration and effortlessly update to your design later with a simple version bump.`,
    // },
    // {
    //   title: `Ships With Built-In Product Design Help`,
    //   description: `Create products that will delight your customers without dealing with financial or resourcing constraints.`,
    // },
    // {
    //   title: `Iteration Friendly Composable Components`,
    //   description: `Composable components make it easy to update product patterns as new data becomes available.`,
    // },
  ],
  //   hero: <img src={Editor} alt={Editor} />,
  hero: <Demo />,
};
