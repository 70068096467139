import {
  HarmonyColorName,
  HarmonyColorType,
  HarmonyFillStyle,
  HarmonyIconNames,
} from "@blocksfabrik/harmony-core";
import { HaButton, HaIcon } from "@blocksfabrik/harmony-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { navigateLink } from "../utils/navigateLogic";
import { Logo } from "./resources/Logo";
import "./scss/Navigation.scss";

export interface NavigationItemProps {
  label: string;
  labelDecoration?: JSX.Element;
  route?: string;
  description?: string;
  icon?: JSX.Element;
  children?: NavigationItemProps[];
}

export function LinkOrItem(props: {
  children: JSX.Element;
  item: NavigationItemProps;
  onOpen?: () => void;
  onClose?: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const resolveClose = () => {
    setIsOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const resolveOpen = () => {
    setIsOpen(true);
    if (props.onOpen) {
      props.onOpen();
    }
  };
  const onFocus = (e: any) => {
    const target = e.target;
    if (props.item.children) {
      resolveOpen();
      setTimeout(() => {
        const children = target.querySelector(
          ".ha-website-navigation-content-item-children"
        );
        if (children) {
          (Array.from(children.children)[0] as any).focus();
        }
      }, 0);
    }
  };
  const onBlur = (e: any) => {
    setTimeout(() => {
      const parent = props.item.children
        ? e.target.closest(".ha-website-navigation-content-item")
        : undefined;
      if (
        props.item.children &&
        parent &&
        !parent.contains(document.activeElement)
      ) {
        resolveClose();
      }
    }, 0);
  };
  const onMouseOver = (e: any) => {
    if (props.item.children) {
      resolveOpen();
    }
  };
  const onMouseOut = (e: any) => {
    if (props.item.children && window.innerWidth > 800) {
      resolveClose();
    }
  };
  const navigateTo = useNavigate();
  if (!props.item.children && props.item.route) {
    return (
      <Link
        key={props.item.label}
        onClick={(e) => {
          resolveClose();
          navigateLink(e, `${props.item.route}`, navigateTo);
        }}
        to={props.item.route}
        tabIndex={0}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={`${
          isOpen ? "active" : ""
        } ha-website-navigation-content-item`}
      >
        {props.children}
      </Link>
    );
  } else {
    return (
      <div
        onKeyDown={(e) => {
          const logo = document.body.querySelector("a.ha-website-logo") as any;
          const shift = e.shiftKey;
          if (shift && e.key === "Tab") {
            resolveClose();
            logo.focus();
          }
        }}
        key={props.item.label}
        onClick={(e) => {
          if (
            (e.target as any).closest(
              ".ha-website-navigation-content-item-children"
            )
          ) {
            resolveClose();
          }
          e.preventDefault();
        }}
        tabIndex={0}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        className={`${
          isOpen ? "active" : ""
        } ha-website-navigation-content-item`}
      >
        {props.children}
      </div>
    );
  }
}

export function NavigationItem(props: {
  item: NavigationItemProps;
  onOpen?: () => void;
  onClose?: () => void;
  mobile?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigateTo = useNavigate();
  const resolveClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setIsOpen(false);
  };
  const resolveOpen = () => {
    if (props.onOpen) {
      props.onOpen();
    }
    setIsOpen(true);
  };
  return (
    <LinkOrItem
      key={"parent" + props.item.label}
      {...props}
      onClose={resolveClose}
      onOpen={resolveOpen}
    >
      <>
        <span className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center">
          {props.item.label}
          {props.item.labelDecoration}
        </span>
        {props.item.children && (isOpen || props.mobile) && (
          <div className="ha-website-navigation-content-item-children">
            {props.item.children.map((child, i) => {
              return (
                <Link
                  onClick={(e) => {
                    resolveClose();
                    navigateLink(e, `${child.route}`, navigateTo);
                  }}
                  key={"inner-link" + i}
                  className="ha-website-navigation-content-deep-item"
                  to={child.route || ""}
                >
                  <div
                    className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center"
                    key={"label" + i}
                  >
                    {child.icon}
                    <div className="ha-website-spacer-horizontal-xsmall" />
                    <div className="ha-display-flex ha-display-flex-column ha-display-flex-justify-content-center">
                      <h6 className="web"> {child.label}</h6>
                      <span className="ha-website-text-content xsmall">
                        {child.description}
                      </span>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </>
    </LinkOrItem>
  );
}

export function Navigation(props: {
  links: NavigationItemProps[];
  onRequestAccess: () => void;
  theme?: string;
}) {
  const [isAnyItemOpen, setIsAnyItemOpen] = useState(false);
  return (
    <div
      className={`${props.theme === "dark" ? "dark" : ""} ${
        isAnyItemOpen ? "ha-website-navigation-is-open" : ""
      } ha-display-flex ha-display-flex-column ha-website-navigation`}
    >
      <div className="ha-website-navigation-content">
        <Logo theme={props.theme} />
        <div className="ha-website-navigation-desktop ha-website-navigation-content-items">
          {props.links.map((link, i) => {
            return (
              <NavigationItem
                onClose={() => setIsAnyItemOpen(false)}
                onOpen={() => setIsAnyItemOpen(true)}
                key={"navigationItem" + i}
                item={link}
              />
            );
          })}
        </div>
        <HaButton
          className="ha-website-navigation-desktop"
          theme={props.theme === "light" ? "light" as any: "dark" as any}
          colorType={HarmonyColorType.Primary}
          onHaClick={() => {
            const hasInlineForm = document.getElementsByClassName(
              "ha-website-inline-form"
            );
            if (hasInlineForm.length !== 0) {
              hasInlineForm[0].scrollIntoView({ block: "center" });
            } else {
              props.onRequestAccess();
            }
          }}
        >
          <span className="ha-body-text-weight-medium">Get Early Access</span>
        </HaButton>
        <div className="ha-website-navigation-mobile">
          <HaButton
            fillStyle={HarmonyFillStyle.Ghost}
            theme={props.theme === "light" ? "light" as any : "dark" as any}
            colorType={HarmonyColorType.Neutral}
            onHaClick={() => {
              setIsAnyItemOpen(!isAnyItemOpen);
            }}
          >
            <HaIcon
              iconName={
                !isAnyItemOpen
                  ? HarmonyIconNames.Menu
                  : HarmonyIconNames.SmallCross
              }
              size={32}
              slot="left-icon"
              color={
                props.theme === "light"
                  ? HarmonyColorName.Black
                  : HarmonyColorName.White
              }
            />
          </HaButton>
        </div>
      </div>
      {isAnyItemOpen && (
        <div className="ha-website-navigation-content-mobile">
          {props.links.map((link, i) => {
            return (
              <NavigationItem
                mobile={true}
                onClose={() => setIsAnyItemOpen(false)}
                onOpen={() => setIsAnyItemOpen(true)}
                key={"navigationItem" + i}
                item={link}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
