import "./scss/Pricing.scss";
import Checkmark from "../use-cases/resources/Solution.svg";
import { HaButton, HaSpacer, HaTag } from "@blocksfabrik/harmony-react";
import {
  HarmonyColorType,
  HarmonySpacerSize,
} from "@blocksfabrik/harmony-core";

const plans = [
  // {
  //     title: "Founders",
  //     description : "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet consectetur.",
  //     features: [
  //         {
  //             text: '1-2 seats',
  //         },
  //         {
  //             text: 'One design system',
  //         },
  //         {
  //             text: 'Unlimited design system versions',
  //         },
  //         {
  //             text: 'Access to editor',
  //         },
  //         {
  //             text: 'Private package for components',
  //         },
  //         {
  //             text: 'Design tokens in Figma',
  //         },
  //         {
  //             text: 'Generate components in Figma',
  //         },
  //         {
  //             text: 'Documentation site',
  //         },
  //         {
  //             text: 'Custom components',
  //         },
  //         {
  //             text: 'White glove onboarding',
  //             alpha: true,
  //         },
  //         {
  //             text: 'Additional design systems',
  //             hidden: true
  //         },
  //         {
  //             text: 'Dedicated support',
  //             hidden: true
  //         },
  //         {
  //             text: 'Enhanced Security',
  //             hidden: true
  //         },
  //         {
  //             text: 'Audit & Compliance',
  //             hidden: true
  //         }
  //     ],
  //     cta: 'Request Access'
  // },
  {
    title: "Startups",
    description:
      "Start building as fast as using a component library, without any of the limitations.",
    features: [
      {
        text: "One design system",
      },
      {
        text: "Unlimited design system versions",
      },
      {
        text: "Access to editor",
      },
      {
        text: "Private package for components",
      },
      {
        text: "Design tokens in Figma",
      },
      {
        text: "Generate components in Figma",
      },
      {
        text: "Documentation site",
      },
      {
        text: "Custom components",
      },
      {
        text: "White glove onboarding",
      },
      {
        text: "15+ seats",
        hidden: true,
      },
      {
        text: "Additional design systems",
        hidden: true,
      },
      {
        text: "Dedicated support",
        hidden: true,
      },
      {
        text: "Enhanced Security",
        hidden: true,
      },
      {
        text: "Audit & Compliance",
        hidden: true,
      },
    ],
    cta: "Request Access",
  },
  {
    title: "Enterprise",
    description:
      "Manage design systems at scale, without tying up valuable resources.",
    features: [
      {
        text: "One design system",
      },
      {
        text: "Unlimited design system versions",
      },
      {
        text: "Access to editor",
      },
      {
        text: "Private package for components",
      },
      {
        text: "Design tokens in Figma",
      },
      {
        text: "Generate components in Figma",
      },
      {
        text: "Documentation site",
      },
      {
        text: "Custom components",
      },
      {
        text: "White glove onboarding",
      },
      {
        text: "15+ seats",
      },
      {
        text: "Additional design systems",
      },
      {
        text: "Dedicated support",
      },
      {
        text: "Enhanced Security",
      },
      {
        text: "Audit & Compliance",
      },
    ],
    cta: "Contact Sales",
  },
];

export function Pricing(props: { onRequestAccess: () => void }) {
  return (
    <>
      <div className="ha-website-page-appear ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <h2 className="web ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        Plans
      </h2>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
        Start small and upgrade for multiple design systems, enhanced security
        and additional features.
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <div className="ha-website-content-price-cards ha-display-flex ha-display-flex-row">
          <>
            {plans.map((plan) => {
              return (
                <div
                  key={plan.title}
                  className="ha-website-content-price-card-column ha-display-flex ha-display-flex-column"
                >
                  <div className="ha-website-content-price-card ha-website-content-key-messages ha-display-flex ha-display-flex-column">
                    <h5 className="web">{plan.title}</h5>
                    <div className="ha-website-spacer-vertical-xsmall" />
                    <div className="ha-size-padding-right-48 ha-website-text-content">
                      {plan.description}
                    </div>
                    <div className="ha-website-spacer-vertical-xsmall" />
                    <div>
                      <HaButton
                        colorType={HarmonyColorType.Primary}
                        onHaClick={() => props.onRequestAccess()}
                      >
                        <span className="ha-body-text-weight-medium">
                          {plan.cta}
                        </span>
                      </HaButton>
                    </div>
                    <div className="ha-website-spacer-vertical-small" />
                  </div>
                  <div className="ha-website-spacer-vertical-medium" />
                  {plan.features.map((feature) => {
                    return (
                      <div
                        style={{
                          filter: feature.hidden ? "grayscale(1)" : "unset",
                          opacity: feature.hidden ? 0.25 : 1,
                        }}
                        key={feature.text}
                        className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center"
                      >
                        <img
                          style={{ opacity: feature.hidden ? 0.5 : 1 }}
                          alt={Checkmark}
                          src={Checkmark}
                        />
                        <div className="ha-website-spacer-horizontal-xsmall" />
                        <div className="ha-website-text-content small ha-display-flex ha-display-flex-align-items-center">
                          {feature.text}
                          {(feature as any).alpha && (
                            <>
                              <HaSpacer
                                size={HarmonySpacerSize.S}
                                orientation="horizontal"
                              />
                              <HaTag colorType={HarmonyColorType.Neutral}>
                                {(feature as any).alpha ? "Limited" : "Soon"}
                              </HaTag>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="ha-website-content-price-card ha-website-spacer-vertical-small" />
                  <div className="ha-website-spacer-vertical-small" />
                  <div className="bottom ha-website-content-key-messages ha-display-flex ha-display-flex-column">
                    <div className="ha-website-spacer-vertical-xsmall" />
                    <div className="ha-size-padding-right-48 ha-website-text-content">
                      {plan.description}
                    </div>
                    <div className="ha-website-spacer-vertical-xsmall" />
                    <div>
                      <HaButton
                        colorType={HarmonyColorType.Primary}
                        onHaClick={() => props.onRequestAccess()}
                      >
                        <span className="ha-body-text-weight-medium">
                          {plan.cta}
                        </span>
                      </HaButton>
                    </div>
                    <div className="ha-website-spacer-vertical-small" />
                  </div>
                </div>
              );
            })}
          </>
        </div>
      </div>
    </>
  );
}
