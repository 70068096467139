import Clean from "../../resources/Clean.svg";
import Documented from "../../resources/Documented.svg";
import DarkMode from "../../resources/DarkMode.svg";
import Accessibility from "../../resources/Accessibility.svg";
import Framework from "../../resources/Framework.svg";
import Faster from "../../resources/Faster.svg";
import Cloud from "../../resources/Cloud.svg";
import People from "../../resources/People.svg";
import Shapes from "../../resources/Shapes.svg";
import ColorBucket from "../../resources/ColorBucket.svg";
import DoubleSquare from "../../resources/DoubleSquare.svg";
import Gauge from "../../resources/Gauge.svg";

export const homepageFeatureProps = {
  features: [
    {
      category: "Designers",
      title: "Accelerate Design",
      description:
        "Move faster and create production ready components without writing code yourself.",
      icon: <img alt="Faster" src={Faster} />,
    },
    {
      category: "Designers",
      title: "Manage Tokens",
      description:
        "Configure tokens in a single place and use them in Figma and code.",
      icon: <img alt="ColorBucket" src={ColorBucket} />,
    },
    {
      category: "Designers",
      title: "Construct Patterns",
      description:
        "Define and distribute patterns to design system consumers across your organization.",
      icon: <img alt="Shapes" src={Shapes} />,
    },
    {
      category: "Designers",
      title: "Simplify Hand-Off",
      description:
        "Enable developers to inspect design components in Figma to easily retrive code.",
      icon: <img alt="People" src={People} />,
    },
    {
      category: "Developers",
      title: "Fully Composable",
      description: `Create virtually anything by combining building blocks with ease.`,
      icon: <img alt="DoubleSquare" src={DoubleSquare} />,
    },
    {
      category: "Developers",
      title: "Framework Agnostic",
      description:
        "Use your design system with React, Angular, Vue and even no framework at all.",
      icon: <img alt="Framework" src={Framework} />,
    },
    {
      category: "Developers",
      title: "Consistent API",
      description:
        "Use the same API across all components and stop wrangling conflicts.",
      icon: <img alt="Clean" src={Clean} />,
    },
    {
      category: "Developers",
      title: "Performance First",
      description: "We worry about component performance so you don't have to.",
      icon: <img alt="Gauge" src={Gauge} />,
    },
    {
      category: "Both",
      title: "Documented For You",
      description:
        "Get documentation for each version and extend it with examples and guidelines.",
      icon: <img alt="FaDocumentedster" src={Documented} />,
    },
    {
      category: "Both",
      title: "Update Seamlessly",
      description:
        "Collaborate on changes and new versions without impacting your live product.",
      icon: <img alt="Cloud" src={Cloud} />,
    },
    {
      category: "Both",
      title: "Accessibility Built-In",
      description:
        "Adhere to WAI-ARIA best practices so your products become more inclusive.",
      icon: <img alt="Accessibility" src={Accessibility} />,
    },
    {
      category: "Both",
      title: "Theme Support",
      description:
        "Enable dark mode in your product with built-in support in your design system.",
      icon: <img alt="DarkMode" src={DarkMode} />,
    },
  ],
};
