import { Link, useNavigate } from "react-router-dom";
import { navigateLink } from "../../utils/navigateLogic";
import "./scss/Logo.scss";

export function Logo(props: { theme: string | undefined }) {
  const navigateTo = useNavigate();
  return (
    <Link
      to={"/"}
      onClick={(e) => {
        navigateLink(e, "/", navigateTo);
      }}
      className="ha-website-logo"
      tabIndex={0}
    >
      <svg
        width="205"
        height="32"
        viewBox="0 0 205 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M54.8534 25.7491V7.15553H61.6949C63.9515 7.15553 65.5884 7.57666 66.6055 8.41894C67.6385 9.24532 68.1549 10.3578 68.1549 11.7562C68.1549 12.6462 67.9325 13.4011 67.4875 14.0208C67.0425 14.6247 66.3592 15.0538 65.4374 15.3081V15.8802C66.6293 16.1186 67.5352 16.6033 68.1549 17.3343C68.7747 18.0494 69.0846 19.0427 69.0846 20.314C69.0846 21.9509 68.5364 23.2699 67.4398 24.2711C66.3592 25.2564 64.619 25.7491 62.2193 25.7491H54.8534ZM58.0715 23.2461H61.9571C63.2761 23.2461 64.2376 22.9759 64.8415 22.4356C65.4454 21.8953 65.7473 21.1404 65.7473 20.171C65.7473 19.1698 65.4295 18.415 64.7938 17.9064C64.174 17.3979 63.2364 17.1436 61.9809 17.1436H58.0715V23.2461ZM58.0715 14.6883H61.5757C62.6404 14.6883 63.4589 14.4897 64.031 14.0924C64.6031 13.6792 64.8892 13.0435 64.8892 12.1853C64.8892 10.5008 63.7847 9.65851 61.5757 9.65851H58.0715V14.6883Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M72.6327 25.7491C72.2354 25.2723 71.9335 24.7241 71.7269 24.1043C71.5362 23.4845 71.4409 22.6104 71.4409 21.4821V6.67877H74.6828V21.1007C74.6828 22.3403 74.8179 23.2938 75.088 23.9612C75.3582 24.6128 75.7078 25.1611 76.1369 25.6061V25.7491H72.6327Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M84.0865 26.1067C81.8298 26.1067 80.1373 25.4313 79.009 24.0804C77.8966 22.7137 77.3404 20.8862 77.3404 18.5977C77.3404 16.3093 77.8966 14.4897 79.009 13.1388C80.1373 11.7721 81.8298 11.0888 84.0865 11.0888C86.3749 11.0888 88.0754 11.7721 89.1878 13.1388C90.3002 14.4897 90.8565 16.3093 90.8565 18.5977C90.8565 20.8862 90.3002 22.7137 89.1878 24.0804C88.0754 25.4313 86.3749 26.1067 84.0865 26.1067ZM84.1103 23.7705C85.2863 23.7705 86.1445 23.3256 86.6848 22.4356C87.241 21.5298 87.5191 20.2505 87.5191 18.5977C87.5191 16.945 87.241 15.6736 86.6848 14.7837C86.1445 13.8778 85.2863 13.4249 84.1103 13.4249C82.9184 13.4249 82.0444 13.8778 81.4882 14.7837C80.9478 15.6736 80.6777 16.945 80.6777 18.5977C80.6777 20.2505 80.9478 21.5298 81.4882 22.4356C82.0444 23.3256 82.9184 23.7705 84.1103 23.7705Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M98.9502 26.1067C96.8048 26.1067 95.1758 25.4313 94.0634 24.0804C92.9669 22.7137 92.4186 20.8862 92.4186 18.5977C92.4186 16.2298 92.9907 14.3864 94.1349 13.0673C95.295 11.7483 96.908 11.0888 98.974 11.0888C100.754 11.0888 102.168 11.5735 103.217 12.5429C104.266 13.5123 104.902 14.8552 105.124 16.5715H101.954C101.827 15.6021 101.509 14.8472 101 14.3069C100.508 13.7666 99.8481 13.4964 99.0217 13.4964C97.9251 13.4964 97.1067 13.9493 96.5664 14.8552C96.026 15.7451 95.7559 16.9926 95.7559 18.5977C95.7559 20.2028 96.026 21.4583 96.5664 22.3641C97.1067 23.2541 97.9172 23.699 98.9978 23.699C99.8401 23.699 100.508 23.4289 101 22.8885C101.509 22.3482 101.827 21.5934 101.954 20.6239H105.124C104.886 22.3403 104.234 23.6831 103.169 24.6525C102.121 25.622 100.714 26.1067 98.9502 26.1067Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M107.548 25.7491V6.67877H110.742V17.0959H111.887L116.225 11.4463H119.706V11.5894L114.557 18.1448L120.278 25.6061V25.7491H116.464L111.887 19.6228H110.742V25.7491H107.548Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M126.798 26.1067C125.336 26.1067 124.152 25.8683 123.247 25.3915C122.357 24.8989 121.689 24.2711 121.244 23.5083C120.815 22.7455 120.577 21.9668 120.529 21.1722H123.628C123.692 21.6172 123.85 22.0383 124.105 22.4356C124.375 22.8329 124.74 23.1508 125.201 23.3891C125.662 23.6275 126.242 23.7467 126.941 23.7467C127.736 23.7467 128.332 23.5957 128.729 23.2938C129.127 22.9759 129.325 22.5628 129.325 22.0542C129.325 21.6251 129.158 21.2596 128.825 20.9577C128.491 20.6398 127.966 20.3538 127.251 20.0995L125.535 19.5274C124.74 19.2413 124.009 18.9315 123.342 18.5977C122.69 18.2481 122.166 17.8111 121.769 17.2866C121.387 16.7463 121.197 16.063 121.197 15.2366C121.197 13.997 121.657 12.9958 122.579 12.233C123.501 11.4702 124.788 11.0888 126.441 11.0888C127.633 11.0888 128.61 11.2874 129.373 11.6847C130.152 12.0661 130.74 12.5906 131.137 13.258C131.534 13.9096 131.765 14.6406 131.828 15.4511H128.848C128.769 14.8631 128.523 14.3705 128.11 13.9732C127.712 13.5759 127.116 13.3772 126.322 13.3772C125.638 13.3772 125.114 13.5202 124.748 13.8063C124.399 14.0924 124.224 14.4738 124.224 14.9505C124.224 15.4273 124.399 15.8246 124.748 16.1424C125.114 16.4444 125.662 16.7225 126.393 16.9767L128.086 17.525C128.88 17.7952 129.603 18.1051 130.255 18.4547C130.906 18.7884 131.415 19.2255 131.781 19.7658C132.162 20.2902 132.353 20.9656 132.353 21.792C132.353 23.0792 131.876 24.1202 130.922 24.9148C129.985 25.7094 128.61 26.1067 126.798 26.1067Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M135.354 25.7491V13.8063H133.304V11.4463H135.354V10.5643C135.354 9.78564 135.489 9.10229 135.76 8.51429C136.03 7.92629 136.475 7.46542 137.095 7.13169C137.73 6.79796 138.596 6.63109 139.693 6.63109C140.09 6.63109 140.464 6.65493 140.813 6.70261V9.03872C140.623 8.99104 140.368 8.96721 140.05 8.96721C139.431 8.96721 139.01 9.11023 138.787 9.39629C138.58 9.68234 138.477 10.0955 138.477 10.6359V11.4463H140.813V13.8063H138.477V25.7491H135.354Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M147.965 26.059C146.693 26.059 145.629 25.7491 144.77 25.1293C143.912 24.4936 143.261 23.6116 142.816 22.4833C142.387 21.355 142.172 20.0518 142.172 18.5739C142.172 17.08 142.387 15.7769 142.816 14.6645C143.261 13.552 143.912 12.6859 144.77 12.0661C145.644 11.4463 146.717 11.1365 147.988 11.1365C149.021 11.1365 149.856 11.3431 150.491 11.7562C151.143 12.1694 151.62 12.6859 151.922 13.3057H152.494V11.4463H155.426V25.7491H152.494V23.8897H151.922C151.62 24.4777 151.143 24.9863 150.491 25.4154C149.856 25.8444 149.013 26.059 147.965 26.059ZM148.704 23.6275C149.784 23.6275 150.642 23.1746 151.278 22.2688C151.914 21.347 152.232 20.1233 152.232 18.5977C152.232 17.0721 151.914 15.8564 151.278 14.9505C150.642 14.0288 149.784 13.5679 148.704 13.5679C147.687 13.5679 146.9 13.997 146.344 14.8552C145.787 15.7133 145.509 16.9609 145.509 18.5977C145.509 20.2346 145.787 21.4821 146.344 22.3403C146.9 23.1984 147.687 23.6275 148.704 23.6275Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M166.463 26.059C165.382 26.059 164.516 25.8444 163.864 25.4154C163.229 24.9863 162.76 24.4777 162.458 23.8897H161.886V25.7491H158.954V6.67877H162.172V13.0912H162.744C163.078 12.4873 163.547 12.0105 164.151 11.6609C164.754 11.3113 165.533 11.1365 166.487 11.1365C167.726 11.1365 168.767 11.4463 169.609 12.0661C170.468 12.6859 171.111 13.552 171.54 14.6645C171.985 15.7769 172.208 17.08 172.208 18.5739C172.208 20.0518 171.985 21.355 171.54 22.4833C171.111 23.6116 170.468 24.4936 169.609 25.1293C168.767 25.7491 167.718 26.059 166.463 26.059ZM165.676 23.6275C166.693 23.6275 167.48 23.1984 168.036 22.3403C168.592 21.4821 168.87 20.2346 168.87 18.5977C168.87 16.9609 168.592 15.7133 168.036 14.8552C167.48 13.997 166.693 13.5679 165.676 13.5679C164.596 13.5679 163.737 14.0288 163.102 14.9505C162.466 15.8564 162.148 17.0721 162.148 18.5977C162.148 20.1233 162.466 21.347 163.102 22.2688C163.737 23.1746 164.596 23.6275 165.676 23.6275Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M174.758 25.7491V11.4463H177.667V13.9732H178.239C178.334 13.5441 178.509 13.1229 178.763 12.7098C179.017 12.2966 179.407 11.9628 179.931 11.7086C180.472 11.4384 181.203 11.3033 182.124 11.3033H182.744V14.2592H181.862C180.48 14.2592 179.486 14.6168 178.882 15.3319C178.278 16.0471 177.977 17.1357 177.977 18.5977V25.7491H174.758Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M185.088 25.7491V11.4463H188.306V25.7491H185.088ZM186.709 9.63467C186.121 9.63467 185.668 9.47575 185.35 9.15791C185.032 8.82418 184.873 8.3951 184.873 7.87066C184.873 7.34623 185.032 6.92509 185.35 6.60725C185.668 6.27352 186.121 6.10666 186.709 6.10666C187.313 6.10666 187.765 6.27352 188.067 6.60725C188.385 6.92509 188.544 7.34623 188.544 7.87066C188.544 8.3951 188.385 8.82418 188.067 9.15791C187.765 9.47575 187.313 9.63467 186.709 9.63467Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M191.865 25.7491V6.67877H195.059V17.0959H196.204L200.542 11.4463H204.022V11.5894L198.873 18.1448L204.595 25.6061V25.7491H200.78L196.204 19.6228H195.059V25.7491H191.865Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M14 8H30V24H14V8Z"
          fill={`${props.theme === "light" ? "#3F20FD" : "white"}`}
        />
        <path
          d="M40 11C40 12.1046 40.8954 13 42 13H44V19H42C40.8954 19 40 19.8954 40 21V25C40 28.866 37.866 32 34 32H32V28H34C35.6569 28 36 26.6569 36 25V21C36 18.913 37.0655 17.0749 38.6822 16C37.0655 14.9251 36 13.087 36 11V7C36 5.34315 35.6569 4 34 4H32V0H34C37.866 0 40 3.13401 40 7V11Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
        <path
          d="M4 11C4 12.1046 3.10457 13 2 13H0V19H2C3.10457 19 4 19.8954 4 21V25C4 28.866 6.13401 32 10 32H12V28H10C8.34315 28 8 26.6569 8 25V21C8 18.913 6.93449 17.0749 5.31779 16C6.93449 14.9251 8 13.087 8 11V7C8 5.34315 8.34315 4 10 4H12V0H10C6.13401 0 4 3.13401 4 7V11Z"
          fill={`${props.theme === "light" ? "black" : "white"}`}
        />
      </svg>
    </Link>
  );
}
