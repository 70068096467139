import { NavigationItem } from "../../../navigation/Navigation";
import { Logo } from "../../../navigation/resources/Logo";

export function Footer(props: { theme: string }) {
  return (
    <>
      <div className="ha-website-footer-block ha-website-grid-column-start-1 ha-website-grid-column-end-2">
        <h5
          className={`web ${props.theme === "dark" ? "ha-website-white" : ""}`}
        >
          Use Cases
        </h5>
        <div className="ha-website-spacer-vertical-small" />
        <NavigationItem
          item={{ label: "Early Startups", route: "/use-cases/startups" }}
        />
        <div className="ha-website-spacer-vertical-xsmall" />
        <NavigationItem
          item={{ label: "Growth Startups", route: "/use-cases/growth" }}
        />
        <div className="ha-website-spacer-vertical-xsmall" />
        <NavigationItem
          item={{ label: "Enterprise", route: "/use-cases/enterprise" }}
        />
      </div>
      <div className="ha-website-footer-block ha-website-grid-column-start-2 ha-website-grid-column-end-3">
        <h5
          className={`web ${props.theme === "dark" ? "ha-website-white" : ""}`}
        >
          Company
        </h5>
        <div className="ha-website-spacer-vertical-small" />
        <NavigationItem item={{ label: "Pricing", route: "/pricing" }} />
        <div className="ha-website-spacer-vertical-xsmall" />
        <NavigationItem item={{ label: "About Us", route: "/about-us" }} />
        <div className="ha-website-spacer-vertical-xsmall" />
        <NavigationItem item={{ label: "Our Vision", route: "/vision" }} />
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
      <Logo theme={props.theme} />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-footer-links ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <NavigationItem
          item={{ label: "Privacy Policy", route: "/privacy-policy" }}
        />
        <NavigationItem
          item={{ label: "Terms", route: "./terms-of-service" }}
        />
        All Rights Reserved ©{new Date().getFullYear()} Fabrikant Tech, Inc.
      </div>

      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
    </>
  );
}
