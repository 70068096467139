import { useState } from "react";
import { CanvasDemo } from "../3d/3dshape";
import "./scss/Star.scss";

export function Star(props: {
  color: string;
  size: number;
  content: JSX.Element;
  shape?: "feature" | "star" | "stop" | "plus";
  className?: string;
  loading?: {
    duration: number;
    color: string;
  };
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}) {
  const [canvasVisible, setCanvasVisible] = useState(false);
  return (
    <div
      onMouseOver={() => {
        if (props.onMouseOver) {
          props.onMouseOver();
        }
      }}
      onMouseOut={() => {
        if (props.onMouseOut) {
          props.onMouseOut();
        }
      }}
      className={`ha-website-star ${props.className}`}
      style={{ width: `${props.size}rem`, height: `${props.size}rem` }}
    >
      <div
        style={{
          padding: `${0.15 * props.size}rem`,
          opacity: canvasVisible ? "1" : "0",
        }}
        className="ha-website-star-content"
      >
        <div
          className="ha-display-flex ha-display-flex-column ha-display-flex-align-items-center"
          style={{
            position: "relative",
            opacity: canvasVisible ? "1" : "0",
          }}
        >
          {props.content}
        </div>
      </div>
      <CanvasDemo
        onVisible={() => {
          setTimeout(() => setCanvasVisible(true), 300);
        }}
        color={props.color}
        type={props.shape}
      />
    </div>
  );
}
