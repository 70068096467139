import './scss/Features.scss';

export function Features(
    props: {
        features: {
            title: string;
            description: string;
            icon: JSX.Element
        }[]
    }
) {
    const renderFeature = (title: string, description: string, icon: JSX.Element, index: number) => {
        const content = () => {
            return (
                <div className='ha-website-feature-content-item'>
                    {icon}
                    <div className='ha-website-spacer-vertical-xsmall' />
                    <h4 className='web ha-website-white'>{title}</h4>
                    <div className='ha-website-spacer-vertical-xsmall' />
                    <div className='ha-website-text-content-padded ha-website-white ha-website-text-content'>{description}</div>
                </div>
            )
        }
        if (index % 2 === 0 || index === 0) {
            return (
                <div key={"feature"+index} className='ha-website-grid-column-start-1 ha-website-grid-column-end-3'>
                    {content()}
                    <div className='ha-website-spacer-vertical-medium' />
                </div>
            )
        } else {
            return (
                <div key={"feature"+index} className='ha-website-grid-column-start-3 ha-website-grid-column-end-5'>
                    {content()}
                    <div className='ha-website-spacer-vertical-medium' />
                </div>
            )
        }
    }

    return (
        <>
            {props.features.map((feature,i) => {
                return renderFeature(feature.title, feature.description, feature.icon, i);
            })}
        </>
    )
}