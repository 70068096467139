import {
  HarmonyColorType,
  HarmonyFillStyle,
  HarmonySize,
} from "@blocksfabrik/harmony-core";
import {
  HaButton,
  HaControlGroup,
  HaInput,
  HaProgress,
  HaTextArea,
  HaTooltip,
} from "@blocksfabrik/harmony-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./scss/RequestAccess.scss";

export function RequestAccess(props: {
  onClose: () => void;
  onSuccessForm?: () => void;
  inline?: boolean;
}) {
  let defaultElement: any = undefined;
  const [isElementFocused, setElementFocused] = useState(defaultElement);
  const location = useLocation();
  const [formStatus, setFormStatus] = useState<
    "unsent" | "sent" | "error" | "submitting"
  >("unsent");
  const [triedOnce, setTriedOnce] = useState(false);
  const [showPrompt, setPrompt] = useState(false);
  const [formData, setFormData] = useState({
    EMAIL: "",
    FNAME: "",
    LNAME: "",
    CNAME: "",
    CCOMMENT: "",
    CTYPE: "location",
  });

  const handlePreventClick = (e: any) => {
    if (
      !triedOnce &&
      (formData.EMAIL.includes("@gmail.com") ||
        formData.EMAIL.includes("@yahoo.com") ||
        formData.EMAIL.includes("@hey.com") ||
        formData.EMAIL.includes("@test.com"))
    ) {
      e.preventDefault();
      setTriedOnce(true);
    }
  };
  const clickToClose = () => {
    if (
      !showPrompt &&
      formData.EMAIL.match(new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) !==
        null
    ) {
      setPrompt(true);
    } else {
      props.onClose();
    }
  };
  const pressEscape = (e: any) => {
    if (e.key === "Escape" && isElementFocused === undefined) {
      e.stopPropagation();
      if (
        !showPrompt &&
        formData.EMAIL.match(
          new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
        ) !== null
      ) {
        setPrompt(true);
      } else {
        props.onClose();
      }
    } else if (e.key === "Escape") {
      isElementFocused.blur();
    }
  };
  const popState = () => {
    props.onClose();
  };
  useEffect(() => {
    window.addEventListener("popstate", popState);
    document.body.addEventListener("beforeunload", popState);
    document.body.addEventListener("keydown", pressEscape);
    return () => {
      document.documentElement.style.overflow = "";
      document.body.removeEventListener("keydown", pressEscape);
      window.removeEventListener("popstate", popState);
      document.body.removeEventListener("beforeunload", popState);
    };
  });
  useEffect(() => {
    if (!props.inline) {
      document.documentElement.style.overflow = "hidden";
      const container = document.getElementsByClassName(
        "ha-website-sign-up"
      )[0] as any;
      container.focus();
    }
  }, []);
  if (props.inline) {
    return (
      <>
        <div
          className="ha-website-inline-form ha-website-grid-column-start-1 ha-website-grid-column-end-3"
          tabIndex={0}
          onKeyDown={pressEscape}
        >
          <div
            style={{ zIndex: 2 }}
            id="mc_embed_signup"
            onClick={(e) => e.stopPropagation()}
          >
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                setFormStatus("submitting");
                fetch(
                  "https://tech.us21.list-manage.com/subscribe/post?u=dcdca5d4679d741fb45559ba7&amp;id=b6e1c0e8f1&amp;f_id=009be4e1f0",
                  {
                    method: "POST",
                    mode: "no-cors",
                    body: new URLSearchParams(
                      new FormData(e.target as any) as any
                    ), // event.target is the form
                  }
                )
                  .then((response) => {
                    if (!response.ok) {
                      setFormStatus("error");
                    }
                    setFormStatus("unsent");
                    if (props.onSuccessForm) {
                      props.onSuccessForm();
                      setFormData({
                        EMAIL: "",
                        FNAME: "",
                        LNAME: "",
                        CNAME: "",
                        CCOMMENT: "",
                        CTYPE: "",
                      });
                    }
                    props.onClose();
                  })
                  .catch(() => {
                    setFormStatus("error");
                  });
              }}
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
            >
              <div id="mc_embed_signup_scroll">
                <HaInput
                  fullWidth={true}
                  size={HarmonySize.Large}
                  value={formData.EMAIL}
                  onHaFocus={(e) => setElementFocused(e.target)}
                  onHaBlur={() => setElementFocused(undefined)}
                  onHaInput={(e) => {
                    setTriedOnce(false);
                    setFormData({
                      EMAIL: (e.target as any).value,
                      FNAME: formData.FNAME,
                      LNAME: formData.LNAME,
                      CNAME: formData.CNAME,
                      CCOMMENT: formData.CCOMMENT,
                      CTYPE: formData.CTYPE,
                    });
                  }}
                  placeholder="Work Email*"
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                />
                <div className="ha-website-spacer-vertical-small" />
                <HaControlGroup className="ha-display-flex" fullWidth={true}>
                  <HaInput
                    className="web"
                    styleOverride={{
                      input: `:host(.web[shape="Rectangular"][size="Large"]) .ha-input-content { min-width: auto; }`,
                    }}
                    fullWidth={true}
                    size={HarmonySize.Large}
                    onHaFocus={(e) => setElementFocused(e.target)}
                    onHaBlur={() => setElementFocused(undefined)}
                    onHaInput={(e) => {
                      setTriedOnce(false);
                      setFormData({
                        FNAME: (e.target as any).value,
                        EMAIL: formData.EMAIL,
                        LNAME: formData.LNAME,
                        CNAME: formData.CNAME,
                        CCOMMENT: formData.CCOMMENT,
                        CTYPE: formData.CTYPE,
                      });
                    }}
                    value={formData.FNAME}
                    placeholder="First name"
                    type="text"
                    name="FNAME"
                    id="mce-FNAME"
                  />
                  <HaInput
                    className="web"
                    styleOverride={{
                      input: `:host(.web[shape="Rectangular"][size="Large"]) .ha-input-content { min-width: auto; }`,
                    }}
                    fullWidth={true}
                    size={HarmonySize.Large}
                    onHaFocus={(e) => setElementFocused(e.target)}
                    onHaBlur={() => setElementFocused(undefined)}
                    onHaInput={(e) => {
                      setTriedOnce(false);
                      setFormData({
                        LNAME: (e.target as any).value,
                        EMAIL: formData.EMAIL,
                        FNAME: formData.FNAME,
                        CNAME: formData.CNAME,
                        CCOMMENT: formData.CCOMMENT,
                        CTYPE: formData.CTYPE,
                      });
                    }}
                    value={formData.LNAME}
                    placeholder="Last name"
                    type="text"
                    name="LNAME"
                    id="mce-LNAME"
                  />
                </HaControlGroup>

                <div className="ha-website-spacer-vertical-small" />
                <HaInput
                  fullWidth={true}
                  value={formData.CNAME}
                  size={HarmonySize.Large}
                  onHaFocus={(e) => setElementFocused(e.target)}
                  onHaBlur={() => setElementFocused(undefined)}
                  onHaInput={(e) => {
                    setTriedOnce(false);
                    setFormData({
                      CNAME: (e.target as any).value,
                      EMAIL: formData.EMAIL,
                      FNAME: formData.FNAME,
                      LNAME: formData.LNAME,
                      CCOMMENT: formData.CCOMMENT,
                      CTYPE: formData.CTYPE,
                    });
                  }}
                  placeholder="Company name"
                  type="text"
                  name="CNAME"
                  id="mce-CNAME"
                />
                <div className="ha-website-spacer-vertical-small" />
                <HaTextArea
                  fullWidth={true}
                  size={HarmonySize.Large}
                  onHaFocus={(e) => setElementFocused(e.target)}
                  onHaBlur={() => setElementFocused(undefined)}
                  onHaInput={(e) => {
                    setTriedOnce(false);
                    setFormData({
                      CCOMMENT: (e.target as any).value,
                      EMAIL: formData.EMAIL,
                      FNAME: formData.FNAME,
                      LNAME: formData.LNAME,
                      CNAME: formData.CNAME,
                      CTYPE: formData.CTYPE,
                    });
                  }}
                  value={formData.CCOMMENT}
                  placeholder="Tell us a bit about what you are building"
                  rows={2}
                  name="CCOMMENT"
                  id="mce-CCOMMENT"
                />
                <input
                  hidden
                  className={`ha-website-input large`}
                  value={location.pathname}
                  name="CTYPE"
                  id="mce-CTYPE"
                />
                <div className="ha-website-spacer-vertical-small" />
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_dcdca5d4679d741fb45559ba7_b6e1c0e8f1"
                    tabIndex={-1}
                    value=""
                  />
                </div>
                {showPrompt && (
                  <>
                    <div className="ha-website-banner-prompt">
                      Thanks for filling out the email. If you feel the form is
                      too long you can always submit it like this.
                    </div>
                    <div className="ha-website-spacer-vertical-small" />
                  </>
                )}
                <div className="optionalParent">
                  <div className="clear foot">
                    <HaTooltip
                      disabled={
                        formData.EMAIL.match(
                          new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
                        ) !== null
                      }
                    >
                      <div>
                        {(formData.EMAIL === "" ||
                          formData.EMAIL.match(
                            new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
                          ) === null) && (
                          <span>Please enter a valid email</span>
                        )}
                        {(formData.EMAIL.includes("@gmail.com") ||
                          formData.EMAIL.includes("@yahoo.com") ||
                          formData.EMAIL.includes("@hey.com") ||
                          formData.EMAIL.includes("@test.com")) && (
                          <span>
                            {
                              "Do you have a company email? We're prioritizing companies for early access."
                            }
                          </span>
                        )}
                      </div>
                      <HaButton
                        slot="target"
                        active={formStatus === "submitting"}
                        disabled={
                          formData.EMAIL.match(
                            new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
                          ) === null
                        }
                        fillStyle={
                          formStatus === "submitting"
                            ? HarmonyFillStyle.Ghost
                            : HarmonyFillStyle.Solid
                        }
                        size={HarmonySize.Large}
                        colorType={HarmonyColorType.Primary}
                        onHaClick={handlePreventClick}
                        type="submit"
                        value="Subscribe"
                        id="mc-embedded-subscribe"
                      >
                        {formStatus === "submitting" && (
                          <HaProgress
                            slot="progress"
                            shape="circular"
                            size={HarmonySize.Normal}
                          />
                        )}
                        <span className="ha-body-text-weight-medium">
                          Submit
                        </span>
                      </HaButton>
                    </HaTooltip>

                    {/* <button
                    style={{
                      backgroundColor:
                        formStatus === "submitting" ? "unset !important" : "",
                    }}
                    onClick={handlePreventClick}
                    disabled={
                      formStatus === "submitting" ||
                      formData.EMAIL === "" ||
                      formData.EMAIL.includes("@test.com") ||
                      formData.EMAIL.match(
                        new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
                      ) === null
                    }
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="ha-website-button large"
                  >
                    {formStatus !== "submitting" && <span>Submit</span>}
                    {formStatus === "submitting" && (
                      <HaProgress shape="circular" size={HarmonySize.Normal} />
                    )}
                  </button> */}
                    {/* <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/> */}
                    <p style={{ display: "none" }} className="brandingLogo">
                      <a
                        href="http://eepurl.com/ijcWjT"
                        title="Mailchimp - email marketing made easy and fun"
                      >
                        <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          key={"external"}
          className="ha-website-sign-up"
          tabIndex={0}
          onKeyDown={pressEscape}
        >
          <div
            style={{ zIndex: 2 }}
            id="mc_embed_signup"
            onClick={(e) => e.stopPropagation()}
          >
            <h5 className="web">Request Access</h5>
            <div className="ha-website-spacer-vertical-small" />
            <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
              To provide the best support we just need a few details about what
              you are building.
            </div>
            <div className="ha-website-spacer-vertical-small" />
            <form
              onSubmit={(e: any) => {
                e.preventDefault();
                setFormStatus("submitting");
                fetch(
                  "https://tech.us21.list-manage.com/subscribe/post?u=dcdca5d4679d741fb45559ba7&amp;id=b6e1c0e8f1&amp;f_id=009be4e1f0",
                  {
                    method: "POST",
                    mode: "no-cors",
                    body: new URLSearchParams(
                      new FormData(e.target as any) as any
                    ), // event.target is the form
                  }
                )
                  .then((response) => {
                    if (!response.ok) {
                      setFormStatus("error");
                    }
                    setFormStatus("unsent");
                    if (props.onSuccessForm) {
                      props.onSuccessForm();
                    }
                    props.onClose();
                  })
                  .catch(() => {
                    setFormStatus("error");
                  });
              }}
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
            >
              <div id="mc_embed_signup_scroll">
                <HaInput
                  fullWidth={true}
                  size={HarmonySize.Large}
                  value={formData.EMAIL}
                  onHaFocus={(e) => setElementFocused(e.target)}
                  onHaBlur={() => setElementFocused(undefined)}
                  onHaInput={(e) => {
                    setTriedOnce(false);
                    setFormData({
                      EMAIL: (e.target as any).value,
                      FNAME: formData.FNAME,
                      LNAME: formData.LNAME,
                      CNAME: formData.CNAME,
                      CCOMMENT: formData.CCOMMENT,
                      CTYPE: formData.CTYPE,
                    });
                  }}
                  placeholder="Work Email*"
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                />
                <div className="ha-website-spacer-vertical-small" />
                <HaControlGroup className="ha-display-flex" fullWidth={true}>
                  <HaInput
                    className="web"
                    styleOverride={{
                      input: `:host(.web[shape="Rectangular"][size="Large"]) .ha-input-content { min-width: auto; }`,
                    }}
                    fullWidth={true}
                    size={HarmonySize.Large}
                    onHaFocus={(e) => setElementFocused(e.target)}
                    onHaBlur={() => setElementFocused(undefined)}
                    onHaInput={(e) => {
                      setTriedOnce(false);
                      setFormData({
                        FNAME: (e.target as any).value,
                        EMAIL: formData.EMAIL,
                        LNAME: formData.LNAME,
                        CNAME: formData.CNAME,
                        CCOMMENT: formData.CCOMMENT,
                        CTYPE: formData.CTYPE,
                      });
                    }}
                    value={formData.FNAME}
                    placeholder="First name"
                    type="text"
                    name="FNAME"
                    id="mce-FNAME"
                  />
                  <HaInput
                    className="web"
                    styleOverride={{
                      input: `:host(.web[shape="Rectangular"][size="Large"]) .ha-input-content { min-width: auto; }`,
                    }}
                    fullWidth={true}
                    size={HarmonySize.Large}
                    onHaFocus={(e) => setElementFocused(e.target)}
                    onHaBlur={() => setElementFocused(undefined)}
                    onHaInput={(e) => {
                      setTriedOnce(false);
                      setFormData({
                        LNAME: (e.target as any).value,
                        EMAIL: formData.EMAIL,
                        FNAME: formData.FNAME,
                        CNAME: formData.CNAME,
                        CCOMMENT: formData.CCOMMENT,
                        CTYPE: formData.CTYPE,
                      });
                    }}
                    value={formData.LNAME}
                    placeholder="Last name"
                    type="text"
                    name="LNAME"
                    id="mce-LNAME"
                  />
                </HaControlGroup>

                <div className="ha-website-spacer-vertical-small" />
                <HaInput
                  fullWidth={true}
                  size={HarmonySize.Large}
                  onHaFocus={(e) => setElementFocused(e.target)}
                  onHaBlur={() => setElementFocused(undefined)}
                  onHaInput={(e) => {
                    setTriedOnce(false);
                    setFormData({
                      CNAME: (e.target as any).value,
                      EMAIL: formData.EMAIL,
                      FNAME: formData.FNAME,
                      LNAME: formData.LNAME,
                      CCOMMENT: formData.CCOMMENT,
                      CTYPE: formData.CTYPE,
                    });
                  }}
                  placeholder="Company name"
                  type="text"
                  name="CNAME"
                  id="mce-CNAME"
                />
                <div className="ha-website-spacer-vertical-small" />
                <HaTextArea
                  fullWidth={true}
                  size={HarmonySize.Large}
                  onHaFocus={(e) => setElementFocused(e.target)}
                  onHaBlur={() => setElementFocused(undefined)}
                  onHaInput={(e) => {
                    setTriedOnce(false);
                    setFormData({
                      CCOMMENT: (e.target as any).value,
                      EMAIL: formData.EMAIL,
                      FNAME: formData.FNAME,
                      LNAME: formData.LNAME,
                      CNAME: formData.CNAME,
                      CTYPE: formData.CTYPE,
                    });
                  }}
                  value={formData.CCOMMENT}
                  placeholder="Tell us a bit about what you are building"
                  rows={2}
                  name="CCOMMENT"
                  id="mce-CCOMMENT"
                />
                <input
                  hidden
                  className={`ha-website-input large`}
                  value={location.pathname}
                  name="CTYPE"
                  id="mce-CTYPE"
                />
                <div className="ha-website-spacer-vertical-small" />
                <div
                  style={{ position: "absolute", left: "-5000px" }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_dcdca5d4679d741fb45559ba7_b6e1c0e8f1"
                    tabIndex={-1}
                    value=""
                  />
                </div>
                {showPrompt && (
                  <>
                    <div className="ha-website-banner-prompt">
                      Thanks for filling out the email. If you feel the form is
                      too long you can always submit it like this.
                    </div>
                    <div className="ha-website-spacer-vertical-small" />
                  </>
                )}
                <div className="optionalParent">
                  <div className="clear foot">
                    <HaTooltip
                      disabled={
                        formData.EMAIL.match(
                          new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
                        ) !== null
                      }
                    >
                      <div>
                        {(formData.EMAIL === "" ||
                          formData.EMAIL.match(
                            new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
                          ) === null) && (
                          <span>Please enter a valid email</span>
                        )}
                        {(formData.EMAIL.includes("@gmail.com") ||
                          formData.EMAIL.includes("@yahoo.com") ||
                          formData.EMAIL.includes("@hey.com") ||
                          formData.EMAIL.includes("@test.com")) && (
                          <span>
                            {
                              "Do you have a company email? We're prioritizing companies for early access."
                            }
                          </span>
                        )}
                      </div>
                      <HaButton
                        slot="target"
                        active={formStatus === "submitting"}
                        disabled={
                          formData.EMAIL.match(
                            new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
                          ) === null
                        }
                        fillStyle={
                          formStatus === "submitting"
                            ? HarmonyFillStyle.Ghost
                            : HarmonyFillStyle.Solid
                        }
                        size={HarmonySize.Large}
                        colorType={HarmonyColorType.Primary}
                        onHaClick={handlePreventClick}
                        type="submit"
                        value="Subscribe"
                        id="mc-embedded-subscribe"
                      >
                        {formStatus === "submitting" && (
                          <HaProgress
                            slot="progress"
                            shape="circular"
                            size={HarmonySize.Normal}
                          />
                        )}
                        <span className="ha-body-text-weight-medium">
                          Submit
                        </span>
                      </HaButton>
                    </HaTooltip>

                    {/* <button
                    style={{
                      backgroundColor:
                        formStatus === "submitting" ? "unset !important" : "",
                    }}
                    onClick={handlePreventClick}
                    disabled={
                      formStatus === "submitting" ||
                      formData.EMAIL === "" ||
                      formData.EMAIL.includes("@test.com") ||
                      formData.EMAIL.match(
                        new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)
                      ) === null
                    }
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="ha-website-button large"
                  >
                    {formStatus !== "submitting" && <span>Submit</span>}
                    {formStatus === "submitting" && (
                      <HaProgress shape="circular" size={HarmonySize.Normal} />
                    )}
                  </button> */}
                    {/* <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/> */}
                    <p style={{ display: "none" }} className="brandingLogo">
                      <a
                        href="http://eepurl.com/ijcWjT"
                        title="Mailchimp - email marketing made easy and fun"
                      >
                        <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="ha-website-click-target"
          style={{
            top: 0,
            position: "fixed",
            background: "red",
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            opacity: 0,
          }}
          onClick={clickToClose}
        />
      </>
    );
  }
}
