import "./scss/UseCaseLayout.scss";
import { Star } from "../../../decoration/star/Star";
import Cross from "../resources/Problem.svg";
import Checkmark from "../resources/Solution.svg";
import { HaButton } from "@blocksfabrik/harmony-react";
import { HarmonyColorType, HarmonySize, HarmonyTheme } from "@blocksfabrik/harmony-core";

export function UseCaseLayout(props: {
  type: string;
  title: string;
  description: string;
  image: JSX.Element;
  problemDescription: string;
  solutionDescription: string;
  problems: string[];
  solutions: string[];
  keyMessageTitle: string;
  keyMessage: string;
  messages: {
    title: string;
    description: string;
  }[];
  takeAways: string[];
  hero: JSX.Element;
  onRequestAccess: () => void;
  CTA?: string;
}) {
  return (
    <>
      <div className="ha-website-page-appear  ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <h5 className="web small ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        {props.type}
      </h5>
      <h2 className="web ha-website-use-case-title ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <span style={{ zIndex: 1, position: "relative" }}>{props.title}</span>
        <Star
          key={props.title}
          className="ha-website-use-case-star"
          content={<>{props.image}</>}
          size={28}
          color="#3F20FD"
        />
      </h2>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
        {props.description}
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
      <>
        {props.takeAways.map((takeAway, i) => {
          return (
            <div
              className={`ha-website-use-case-takeaway ${
                i === 3 ? "last" : ""
              } ha-website-grid-column-start-${
                i + 1
              } ha-website-grid-column-end-${i + 2}`}
            >
              <h5 className="web small">{takeAway}</h5>
            </div>
          );
        })}
      </>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
      <div className="ha-website-problem-solution ha-display-flex ha-display-flex-row ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <div className="ha-website-problem-container">
          <h5 className="web">Before</h5>
          <div className="ha-website-spacer-vertical-small" />
          <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
            {props.problemDescription}
          </div>
          <div className="ha-website-spacer-vertical-small" />
          {props.problems.map((problem) => {
            return (
              <div
                key={problem}
                className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center"
              >
                <img alt={Cross} src={Cross} />
                <div className="ha-website-spacer-horizontal-xsmall" />
                <div className="ha-website-text-content">{problem}</div>
              </div>
            );
          })}
        </div>
        <div className="ha-website-problem-container solution">
          <h5 className="web ha-website-white">After</h5>
          <div className="ha-website-spacer-vertical-small" />
          <div className="ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
            {props.solutionDescription}
          </div>
          <div className="ha-website-spacer-vertical-small" />
          {props.solutions.map((solution) => {
            return (
              <div
                key={solution}
                className="ha-display-flex ha-display-flex-row ha-display-flex-align-items-center"
              >
                <img alt={Checkmark} src={Checkmark} />
                <div className="ha-website-spacer-horizontal-xsmall" />
                <div className="ha-website-white ha-website-text-content">
                  {solution}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
      {/* <div className="ha-website-content-key-messages  ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <div
          className="ha-display-flex ha-display-flex-row"
          style={{ gap: "24px" }}
        >
          <>
            {props.messages.map((message) => {
              return (
                <div
                  key={message.title}
                  className="ha-website-content-key-message ha-display-flex ha-display-flex-column"
                >
                  <h6 className="web large">{message.title}</h6>
                  <div className="ha-website-spacer-vertical-xsmall" />
                  <div className="ha-website-text-content">
                    {message.description}
                  </div>
                </div>
              );
            })}
          </>
        </div>
      </div>
      <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" /> */}
      <h2 className="ha-website-hide-on-mobile web ha-website-grid-column-start-1 ha-website-grid-column-end-4">
        <span style={{ zIndex: 1, position: "relative" }}>In Action</span>
      </h2>
      <div className="ha-website-hide-on-mobile ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-medium" />
      <div className="ha-website-hide-on-mobile ha-website-carousel-container ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <div className="ha-website-use-case ha-website-carousel-image-container">
          {props.hero}
        </div>
        <div className="ha-website-carousel-filler" />
      </div>
      <div className="ha-website-grid-filler-keep-padding ha-website-grid-filler-carousel ha-website-grid-long-width ha-website-grid-column-start-1 ha-website-grid-column-end-5">
        <h2 className="web ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-5">
          Create Your Design System Without
          <br />
          All The Work
          <Star
            className="ha-website-hero-star"
            content={
              <>
                <h6 className="web ha-website-white">Backed by</h6>
                <div className="ha-website-spacer-vertical-xsmall" />
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_71_4203)">
                    <path
                      d="M0.3396 0.294632L32.3396 0.294632V32.2946H0.3396L0.3396 0.294632Z"
                      fill="white"
                    />
                    <path
                      d="M15.2726 18.4277L9.72656 8.08174H12.2866L15.4866 14.5887C15.4866 14.6957 15.5936 14.8017 15.6996 14.9087C15.8056 15.0157 15.8066 15.1217 15.9126 15.3357L16.0196 15.4427V15.5497C16.1266 15.7627 16.1266 15.8697 16.2326 16.0827C16.3396 16.1897 16.3396 16.4027 16.4456 16.5097C16.5526 16.1897 16.7656 15.9767 16.8726 15.5497C16.9796 15.2297 17.1926 14.9097 17.4056 14.5897L20.6056 8.08274H22.9526L17.4066 18.5347V25.1477H15.2736L15.2726 18.4277Z"
                      fill="#F26625"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_71_4203">
                      <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="translate(0.3396 0.294632)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </>
            }
            size={10}
            color="#F26522"
          />
        </h2>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
        <div className="ha-website-white ha-website-grid-column-start-1 ha-website-grid-column-end-3 ha-website-text-content">
          The new standard for creating design systems. Ship design and code
          from a single source of truth.
        </div>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-small" />
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5">
          <HaButton
            theme={HarmonyTheme.dark}
            size={HarmonySize.Large}
            colorType={HarmonyColorType.Primary}
            onHaClick={() => props.onRequestAccess()}
          >
            <span className="ha-body-text-weight-medium">
              {props.CTA || `Get Early Access`}
            </span>
          </HaButton>
        </div>
        <div className="ha-website-grid-column-start-1 ha-website-grid-column-end-5 ha-website-spacer-vertical-large" />
        <div className="ha-website-carousel-filler" />
      </div>
    </>
  );
}
